import { Responsive } from 'semantic-ui-react';
import { css } from 'styled-components';
import { createCardCarouselTheme } from './cardCarouselTheme';
/**
 * Parse css units to the number of pixels. A single rem is assumed to be 14px.
 *
 * @param {string} cssUnit
 * @returns {number}
 */
export const parseCssUnit = cssUnit => {
  if (typeof cssUnit === 'number') return cssUnit;

  const value = parseFloat(cssUnit);
  if (Number.isNaN(value)) return 0;

  if (cssUnit.indexOf('rem') !== -1) return value * 14;
  return value;
};

/**
 * A style-components template literal for mobile breakpoints.
 *
 * @example
 * styled.div`
 *   max-width: 768px;
 *   ${mobile`
 *     max-width: 100%;
 *   `}
 * `
 */

Responsive.onlyMobile.minWidth = 1;
Responsive.onlyMobile.maxWidth = 767;
Responsive.onlyTablet.minWidth = 768;
Responsive.onlyTablet.maxWidth = 900;
Responsive.onlyComputer.minWidth = 901;
Responsive.onlyComputer.maxWidth = 1480;
Responsive.onlyLargeScreen.minWidth = 1481;
export const mobileOnly = (...args) => css`
  @media only screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
    ${css(...args)};
  }
`;
export const tabletOnly = (...args) => css`
  @media only screen and (min-width: ${
    Responsive.onlyTablet.minWidth
  }px and (max-width: ${Responsive.onlyTablet.maxWidth}px) {
    ${css(...args)};
  }
`;
export const desktopOnly = (...args) => css`
  @media only screen and (min-width: ${
    Responsive.onlyComputer.minWidth
  }px and (max-width: ${Responsive.onlyComputer.maxWidth}px) {
    ${css(...args)};
  }
`;
export const tabletMinimumWidth = (...args) => css`
  @media only screen and (min-width: ${Responsive.onlyTablet.minWidth}px) {
    ${css(...args)};
  }
`;
export const desktopMinimumWidth = (...args) => css`
  @media only screen and (min-width: ${Responsive.onlyComputer.minWidth}px) {
    ${css(...args)};
  }
`;
export const largeDesktopMinimumWidth = (...args) => css`
  @media only screen and (min-width: ${Responsive.onlyLargeScreen.minWidth}px) {
    ${css(...args)};
  }
`;

export const carouselCardHeight = ({ theme }) => theme.carouselCardHeight;
export const carouselCardWidth = ({ theme }) => theme.carouselCardWidth;
export const carouselCardSpacing = ({ theme }) => theme.carouselCardSpacing;
export const carouselCardBorderRadius = ({ theme }) =>
  theme.carouselCardBorderRadius;
export const carouselCardPadding = ({ theme }) => theme.carouselCardPadding;

const cardCarouselTheme = createCardCarouselTheme();
export default {
  ...cardCarouselTheme
};
