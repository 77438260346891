/* global document */
import 'date-input-polyfill-react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import mixpanel from 'mixpanel-browser';
import App from './App';
import './index.d';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';
import getOwnPropertyNames from './util/getOwnPropertyNames';
import setupGoogleAnalytics from './util/setupGoogleAnalytics';

Object.getOwnPropertyNames = getOwnPropertyNames;

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV
  });
}

if (process.env.REACT_APP_GA_TRACKING_ID) {
  setupGoogleAnalytics(process.env.REACT_APP_GA_TRACKING_ID);
}
if (process.env.REACT_APP_MIXPANEL_APP_ID) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_APP_ID);
}

const renderApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

renderApp();

if ((module as any).hot) {
  (module as any).hot.accept('./App', () => renderApp());
}

serviceWorker.unregister();
