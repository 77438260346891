import mixpanel from 'mixpanel-browser';
import { Tracker } from '../types';
import { checkCache, isDifferent, isSame } from '../cache';
import { RootState } from '../../reducers';
import { campaignSummarySelector } from '../../reducers/CampaignSummary';
import { campaignSelector } from '../../reducers/Campaign';

type Props = {
  'App Name': string;
  'Offer Name': string;
  'Offer Type': string;
  'Campaign ID': number;
  'App ID': string;
  'Store ID': number;
};

const prefixEvent = (event: string) => `Kits - ${event}`;

const getCampaignDetails = (state: RootState, campaignId: string) => {
  const campaign = campaignSelector(state, campaignId);
  if (campaign) return campaign;

  return campaignSummarySelector(state, campaignId);
};

const buildProps = (
  campaignDetails: ReturnType<typeof getCampaignDetails>
): Props => {
  return {
    'App ID': campaignDetails.appId,
    'App Name': campaignDetails.appName,
    'Store ID': campaignDetails.storeId,
    'Offer Name': campaignDetails.offer.name,
    'Offer Type': campaignDetails.offer.type,
    'Campaign ID': campaignDetails.id
  };
};

export const trackSelectedHighlight: Tracker = (state, { payload }) => {
  const campaignDetails = getCampaignDetails(state, payload.campaignId);

  const same = checkCache('kitId', campaignDetails.appId, isSame);
  const storeChanged = checkCache(
    'kitStoreId',
    campaignDetails.storeId,
    isDifferent
  );

  if (same || storeChanged) return;

  mixpanel.track(prefixEvent('Selected An App'), {
    ...buildProps(campaignDetails)
  });
};

export const trackSelectedKitCampaignItem: Tracker = (state, { payload }) => {
  const { name, group, campaignId } = payload;
  const props = buildProps(getCampaignDetails(state, campaignId));
  mixpanel.track(`Kits - Performed Action`, {
    'Asset Group': group,
    Action: name,
    ...props
  });
};

export const trackSelectedKitCampaignGroup: Tracker = (state, { payload }) => {
  const { group, category, campaignId } = payload;

  const props = buildProps(getCampaignDetails(state, campaignId));
  mixpanel.track(`Kits - Clicked ${group}`, {
    'Asset Category': category,
    ...props
  });
};

export const trackSelectedCampaign: Tracker = (state, { payload }) => {
  if (checkCache('kitCampaignId', payload.campaignId, isSame)) return;
  const props = buildProps(getCampaignDetails(state, payload.campaignId));
  mixpanel.track(prefixEvent('Selected an Offer'), { ...props });
};

export const trackPreviewedCampaign: Tracker = (_, { payload }) => {
  mixpanel.track(prefixEvent('Previewed Kit Campaign'), {
    'Campaign ID': payload.campaignId
  });
};

export const trackHelpRequested: Tracker = (state, { payload }) => {
  const props = payload.campaignId
    ? buildProps(getCampaignDetails(state, payload.campaignId))
    : {};
  mixpanel.track(prefixEvent('Clicked Help Link'), props);
};

export const trackNavigatedLibrary: Tracker = () => {
  mixpanel.track(prefixEvent(`Clicked Add Campaign Link`));
};

export const trackDownload: Tracker = (state, { payload }) => {
  const props = buildProps(getCampaignDetails(state, payload.campaignId));
  mixpanel.track(prefixEvent(`Downloaded Kit`), {
    ...props
  });
};

export const trackCampaignEdit: Tracker = (state, { payload }) => {
  const props = buildProps(getCampaignDetails(state, payload.campaignId));
  mixpanel.track(prefixEvent(`Edit Offer Button Clicked`), { ...props });
};
