import { Middleware } from 'redux';
import * as Sentry from '@sentry/browser';
import { RootState } from '../reducers';
import { currentUserSelector } from '../reducers/User';

type SentryMiddleware = Middleware<{}, RootState>;

const middleware: SentryMiddleware = store => next => action => {
  const state = store.getState();
  const user = currentUserSelector(state);

  if (user && user.email) {
    Sentry.setUser({ email: user.email });
  }

  Sentry.addBreadcrumb({
    category: 'redux-action',
    message: action.type
  });

  return next(action);
};

export default middleware;
