import find from 'lodash.find';
import filter from 'lodash.filter';
import { ApiState } from '../state/types';
import { DeepPartial } from './types';
import { Campaign } from '../models/campaign';

export type CampaignIteratee =
  | ((campaign: Campaign) => boolean)
  | DeepPartial<Campaign>;

export const selectCampaign = (state: ApiState, campaignId: string) => {
  return state.campaigns[campaignId];
};

export const selectAllCampaigns = (state: ApiState) => {
  return Object.values(state.campaigns);
};

export const findCampaign = (state: ApiState, findBy: CampaignIteratee) =>
  find(state.campaigns, findBy);

export const filterCampaigns = (state: ApiState, filterBy: CampaignIteratee) =>
  filter(state.campaigns, filterBy);
