import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import Button from '../../components/Button';

class SetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      error: null
    };
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { isFetching, onSubmit, errorMessage, onSuccess } = this.props;
    const { password } = this.state;

    if (!this.validate() || isFetching) return;

    const res = await onSubmit(password);
    if (res.error) this.setState({ error: errorMessage(res.error) });
    if (onSuccess && res.data) onSuccess();
  };

  handleInputChange = event => {
    event.preventDefault();

    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  validate = () => {
    const { password, passwordConfirm } = this.state;
    if (password !== passwordConfirm) {
      this.setState({ error: 'Passwords do not match' });
      return false;
    }
    return true;
  };

  renderError() {
    const { error } = this.state;
    return error ? <Message negative>{error}</Message> : null;
  }

  render() {
    const { passwordConfirm, password } = this.state;
    const { labels, submitText, isFetching } = this.props;
    return (
      <Form size="large" onSubmit={this.handleSubmit}>
        {this.renderError()}
        <Form.Input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={this.handleInputChange}
          fluid
          icon="lock"
          iconPosition="left"
          label={labels ? 'Password:' : null}
        />
        <Form.Input
          type="password"
          name="passwordConfirm"
          placeholder="Confirm Password"
          value={passwordConfirm}
          onChange={this.handleInputChange}
          fluid
          icon="repeat"
          iconPosition="left"
          label={labels ? 'Confirm Password:' : null}
        />
        <p>Minimum 8 characters, 1 uppercase, 1 lowercase, and 1 numeric.</p>
        <Button type="submit" fluid loading={isFetching} disabled={isFetching}>
          {submitText}
        </Button>
      </Form>
    );
  }
}

SetPasswordForm.propTypes = {
  onSuccess: PropTypes.func,
  labels: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.func
};

SetPasswordForm.defaultProps = {
  submitText: 'Set Password',
  labels: false,
  onSuccess: () => {},
  errorMessage: error => error.message
};

export default SetPasswordForm;
