/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { handleActions, Reducer } from 'redux-actions';
import TYPES from '../actions/index';
// eslint-disable-next-line import/no-cycle
import { RootState } from './index';
import { EmailState, IAction } from './types';
import { createTemplateFromApi, Template } from '../entities/Template';
import { Content, createContentFromApi } from '../entities/Content';
import { createThemeFromApi } from '../entities/Theme';

const emailState: EmailState = {
  content: [],
  creatingEmail: false,
  emailCreated: false,
  isLoading: false,
  isTestEmailDisabled: false,
  selectedTemplateName: '',
  templates: [],
  themeId: 0,
  themes: []
};

const reducer: Reducer<EmailState, IAction<any>['payload']> = handleActions(
  {
    [TYPES.CLEAR_THEME]: (state: EmailState) => ({
      ...emailState,
      themes: state.themes
    }),
    [TYPES.SELECT_STORE]: () => ({ ...emailState }),
    [TYPES.CLEAR_BUSINESS]: () => ({ ...emailState }),
    [TYPES.CREATE_STORE_EMAIL]: (state: EmailState) => ({
      ...state,
      creatingEmail: true
    }),
    [TYPES.CREATE_STORE_EMAIL_SUCCESS]: (state: EmailState) => ({
      ...state,
      creatingEmail: false,
      emailCreated: true
    }),
    [TYPES.FETCH_CAMPAIGN]: (state: EmailState): EmailState => ({
      ...state,
      isLoading: true
    }),
    [TYPES.FETCH_THEME_SUCCESS]: (state: EmailState, action: IAction<any>) => {
      const { id, content, templates } = action.payload.data;
      const formattedTemplates = templates.map((template: any) =>
        createTemplateFromApi(template)
      );
      const formattedContent = content.map((c: any) => createContentFromApi(c));
      const currentSelectedTemplate = formattedTemplates.find(
        (t: Template) => t.name === state.selectedTemplateName
      );
      return {
        ...state,
        content: formattedContent,
        templates: formattedTemplates,
        isLoading: false,
        selectedTemplateName: currentSelectedTemplate
          ? currentSelectedTemplate.name
          : templates[0].name,
        themeId: id
      };
    },
    [TYPES.FETCH_THEME_FAIL]: (state: EmailState) => ({
      ...state,
      isLoading: false
    }),
    [TYPES.SELECT_TEMPLATE]: (state: EmailState, action: IAction<any>) => ({
      ...state,
      selectedTemplateName: action.payload
    }),
    [TYPES.FETCH_ALL_THEMES]: (state: EmailState) => ({
      ...state,
      isLoading: true
    }),
    [TYPES.FETCH_ALL_THEMES_SUCCESS]: (
      state: EmailState,
      action: IAction<any>
    ) => ({
      ...state,
      isLoading: false,
      themes: action.payload.data.map((theme: any) => createThemeFromApi(theme))
    }),
    [TYPES.TEST_EMAIL_DISABLED]: (state: EmailState, action: IAction<any>) => ({
      ...state,
      isTestEmailDisabled: action.payload
    })
  },
  emailState
);

export default reducer;

// Selectors
export const inputsSelector = (state: RootState) => {
  const { content, selectedTemplateName } = state.Email!;
  return content!.filter((c: Content) =>
    c.stages!.includes(selectedTemplateName!)
  );
};

export const templatesSelector = (state: RootState) => state.Email!.templates;

export const isLoadingSelector = (state: RootState) => state.Email!.isLoading;

export const isTestEmailDisabledSelector = (state: RootState) =>
  state.Email!.isTestEmailDisabled;

export const themesSelector = (state: RootState) => state.Email!.themes;

export const emailFanbaseThemesSelector = (state: RootState) => {
  return themesSelector(state).filter(
    theme => theme.offerType === 'custom-email'
  );
};

export const selectedTemplateSelector = (state: RootState) => {
  const { templates, selectedTemplateName } = state.Email!;
  return templates!.find(
    (template: Template) => template.name === selectedTemplateName
  );
};

export const isCreatingEmailSelector = (state: RootState) =>
  state.Email!.creatingEmail;

export const emailCreatedSelector = (state: RootState) =>
  state.Email!.emailCreated;

export const themeIdSelector = (state: RootState) => state.Email!.themeId;

export const selectedThemeSelector = (state: RootState) => {
  const selectedThemeId = themeIdSelector(state);
  return themesSelector(state).find(theme => theme.id === selectedThemeId);
};

export const defaultValuesSelector = (state: RootState) =>
  inputsSelector(state)!.reduce(
    (acc: any, curr: any) => ({ ...acc, [curr.name]: curr.default }),
    {}
  );
