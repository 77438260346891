export class Theme {
  public id: number;

  public name?: string;

  public description?: string;

  public preview?: string;

  public storeId?: number;

  public offerType?: string;

  public constructor(id: number) {
    this.id = id;
  }
}

export function createThemeFromApi(data: any): Theme {
  const theme = new Theme(data.id);
  theme.name = data.name;
  theme.description = data.description;
  theme.preview = data.preview;
  theme.storeId = data.storeId;
  theme.offerType = data.offerType;
  return theme;
}
