import { AxiosResponse } from 'axios';
import { createStandardAction } from 'typesafe-actions';
import createAxiosAction from '../axios/createAxiosAction';
import { App, AppRun } from '../models/app';

export const findById = {
  request: createAxiosAction('@@api/apps/findById', resolve => (id: string) =>
    resolve({
      request: {
        method: 'get',
        url: `/apps/${id}`
      }
    })
  ),
  success: createStandardAction('@@api/apps/findById_SUCCESS')<
    AxiosResponse<App>
  >(),
  fail: createStandardAction('@@api/apps/findById_FAIL')<{}>()
};

export const findByOfferKey = {
  request: createAxiosAction(
    '@@api/apps/findByOfferKey',
    resolve => (id: string, offerKey: string) =>
      resolve({
        request: {
          method: 'get',
          url: `/apps/${id}/${offerKey}`
        }
      })
  ),
  success: createStandardAction('@@api/apps/findByOfferKey_SUCCESS')<
    AxiosResponse<App>
  >(),
  fail: createStandardAction('@@api/apps/findByOfferKey_FAIL')<{}>()
};

export const run = {
  request: createAxiosAction(
    '@@api/apps/run',
    resolve => ({
      id,
      storeId,
      content,
      offerKey,
      slug,
      rules,
      color,
      offerName
    }: AppRun) =>
      resolve({
        request: {
          method: 'post',
          url: `/apps/${id}/run`,
          data: { storeId, content, offerKey, slug, rules, offerName, color }
        }
      })
  ),
  success: createStandardAction('@@api/apps/run_SUCCESS')<AxiosResponse<App>>(),
  fail: createStandardAction('@@api/apps/run_FAIL')<{}>()
};
