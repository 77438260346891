import queryString from 'query-string';
import { match } from 'react-router-dom';
import { routerReducer as router } from 'react-router-redux';
// eslint-disable-next-line import/no-cycle
import { RootState } from '.';

export default router;

export const urlParamSelector = (state: RootState, urlParam: string) => {
  const query = state.Router.location.search.substr(1);

  const paramMap = query.split('&').reduce((acc: any, param: string) => {
    const [key, value] = param.split('=');
    acc[key] = value;
    return acc;
  }, {});

  return paramMap[urlParam];
};

export const searchParamSelector = (state: RootState) =>
  queryString.parse(state.Router.location.search);

export const pathSelector = (state: RootState) =>
  state.Router.location.pathname;

export const routeParamSelector = (
  routeMatch: match<any>,
  routeParam: string
) => routeMatch.params[routeParam];
