export class Template {
  public displayName?: string;

  public name: string;

  public description?: string;

  public internal?: boolean;

  public preview?: string;

  public emailId?: number;

  public type?: string;

  public offers?: string[];

  public constructor(name: string) {
    this.name = name;
  }
}

export function createTemplateFromApi(data: any): Template {
  const template = new Template(data.name);
  template.displayName = data.displayName;
  template.description = data.description;
  template.internal = data.internal;
  template.preview = data.preview;
  template.emailId = data.emailId;
  template.type = data.type;
  template.offers = data.offers;
  return template;
}
