interface IProps {
  cardHeight?: string;
  cardWidth?: string;
  cardSpacing?: string;
  mobile?: boolean;
  borderRadius?: string;
}

export const cardCarouselVisibleSlides = 4;

export interface ICardCarouselTheme {
  carouselCardBorderRadius: string;
  carouselCardHeight: string;
  carouselCardPadding: string;
  carouselCardSpacing: string;
  carouselCardWidth: string;
}

const defaultCardCarouselTheme: ICardCarouselTheme = {
  carouselCardBorderRadius: '1rem',
  carouselCardHeight: '270px',
  carouselCardPadding: '1rem',
  carouselCardSpacing: '0.5rem',
  carouselCardWidth: '168px'
};

export function createCardCarouselTheme(props?: IProps): ICardCarouselTheme {
  // if no props passed in, return default card carousel theme
  if (!props) return defaultCardCarouselTheme;

  const {
    carouselCardWidth,
    carouselCardHeight,
    carouselCardSpacing,
    carouselCardBorderRadius
  } = defaultCardCarouselTheme;
  const { cardHeight, cardWidth, cardSpacing, borderRadius } = props;

  return {
    ...defaultCardCarouselTheme,
    carouselCardHeight: cardHeight || carouselCardHeight,
    carouselCardWidth: cardWidth || carouselCardWidth,
    carouselCardSpacing: cardSpacing || carouselCardSpacing,
    carouselCardBorderRadius: borderRadius || carouselCardBorderRadius
  };
}
