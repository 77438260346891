import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import colors from '../colors';

const WrappedGridColumn = styled(Grid.Column)`
  word-wrap: break-word;
  margin-left: 2rem;
`;

const RowMenuItem = styled(Grid.Row)`
  background-color: #f5f7f9;
  border-top: 1px solid ${colors.grey};
  :hover,
  :active {
    background-color: #eaeff5;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
`;

const PointerGrid = styled(Grid)`
  cursor: pointer;
`;

const BusinessGrid = ({ businesses, select, ...rest }) => {
  const handleBusinessSelect = businessId => select(businessId);

  const businessItems = businesses.map(business => (
    <RowMenuItem
      key={business.id}
      name={business.name}
      onClick={() => handleBusinessSelect(business.id)}
    >
      <WrappedGridColumn verticalAlign="middle">
        <Header as="h2">{business.name}</Header>
      </WrappedGridColumn>
    </RowMenuItem>
  ));

  return <PointerGrid {...rest}>{businessItems}</PointerGrid>;
};

BusinessGrid.propTypes = {
  businesses: PropTypes.arrayOf(PropTypes.object).isRequired,
  select: PropTypes.func.isRequired
};

export default BusinessGrid;
