import { AxiosResponse } from 'axios';
import { createStandardAction } from 'typesafe-actions';
import createAxiosAction from '../axios/createAxiosAction';
import { Business } from '../models/business';

export const findById = {
  request: createAxiosAction(
    '@@api/businesses/findById',
    resolve => (id: number) =>
      resolve({
        request: {
          method: 'get',
          url: `businesses/${id}`,
          params: { filter: { include: ['stores', 'user'] } }
        }
      })
  ),
  success: createStandardAction('@@api/businesses/findById_SUCCESS')<
    AxiosResponse<Business>
  >(),
  fail: createStandardAction('@@api/businesses/findById_FAIL')<{}>()
};

export const patch = {
  request: createAxiosAction(
    '@@api/businesses/patch',
    resolve => (business: Business) =>
      resolve({
        request: {
          method: 'patch',
          url: `businesses/${business.id}`,
          data: business
        }
      })
  ),
  success: createStandardAction('@@api/businesses/patch_SUCCESS')<
    AxiosResponse<Business>
  >(),
  fail: createStandardAction('@@api/businesses/patch_FAIL')<{}>()
};
