import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { Square, Icon } from '../Square';

const TopRow = styled(Grid.Row)`
  padding: 2rem 0 2rem 0 !important;
`;

const ReactiveCenteredColumn = styled(Grid.Column)`
  @media only screen and (max-width: 767px) {
    text-align: center;
    .ui.stackable.grid > .row > &.column {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }
`;

const HeaderGrid = ({ icon, children }) => (
  <Grid stackable padded>
    <TopRow>
      <Grid.Column width={3} verticalAlign="middle">
        <Square>
          <Icon name={icon} />
        </Square>
      </Grid.Column>
      <ReactiveCenteredColumn width={13}>{children}</ReactiveCenteredColumn>
    </TopRow>
  </Grid>
);

HeaderGrid.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default HeaderGrid;
