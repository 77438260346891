import { createAction } from 'redux-actions';
// import { push } from 'react-router-redux';
// import { generatePath } from 'react-router-dom';
import TYPES, { routeToCampaigns } from './index';
import { findById, patch } from '../services/api/actions/businesses';
// import { RouteNames } from '../util/routes';
import { selectDefaultCard } from './Card';

export const fetchBusiness = findById.request;
export const patchBusiness = patch.request;

export const fetchBusinessAndRoute = id => async dispatch => {
  await dispatch(findById.request(id));
  await dispatch(routeToCampaigns());
  dispatch(selectDefaultCard('/'));
};

export const updateBusinessStatus = createAction(
  TYPES.UPDATE_BUSINESS_STATUS,
  (businessId, businessStatus) => ({
    request: {
      method: 'put',
      url: `businesses/${businessId}/status`,
      data: businessStatus
    }
  })
);

export const fetchBusinessGroup = createAction(
  TYPES.BUSINESS_GROUP_FETCH,
  businessId => ({
    request: { url: `businesses/${businessId}/group` }
  })
);

export const fetchAllBusinesses = createAction(
  TYPES.FETCH_ALL_BUSINESSES,
  () => ({
    request: { url: 'businesses' }
  })
);

export const fetchAndCheckBusiness = id => async dispatch => {
  const response = await dispatch(fetchBusiness(id));
  const { data: business } = response.payload;
  if (!business.status.selfOnboarded && business.user.role !== 'admin') {
    // dispatch(push(generatePath(RouteNames['onboarding.intro'])));
  } else {
    dispatch(routeToCampaigns());
  }
};

export const updateSelfOnboarding = id => async dispatch => {
  dispatch({ type: TYPES.UPDATE_SELF_ONBOARDING });
  await dispatch(updateBusinessStatus(id, { selfOnboarded: new Date() }));
  dispatch({ type: TYPES.UPDATE_SELF_ONBOARDING_SUCCESS });
};

export const updateAcceptTerms = id => async dispatch => {
  dispatch({ type: TYPES.UPDATE_ACCEPT_TERMS });
  await dispatch(updateBusinessStatus(id, { acceptTerms: true }));
  dispatch({ type: TYPES.UPDATE_ACCEPT_TERMS_SUCCESS });
};
