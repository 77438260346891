import { persistReducer } from 'redux-persist';
import { handleActions, combineActions } from 'redux-actions';
import storage from 'redux-persist/lib/storage';
import TYPES from '../actions/index';

const persistConfig = {
  key: 'Modal',
  storage
};

const initialState = {
  welcomeModalOpen: false,
  changePasswordModalOpen: false
};

const reducer = handleActions(
  {
    [TYPES.OPEN_CHANGE_PASSWORD_MODAL]: state => ({
      ...state,
      changePasswordModalOpen: true
    }),
    [TYPES.CLOSE_CHANGE_PASSWORD_MODAL]: state => ({
      ...state,
      changePasswordModalOpen: false
    }),
    [TYPES.UPDATE_SELF_ONBOARDING_SUCCESS]: state => ({
      ...state,
      welcomeModalOpen: true
    }),
    [combineActions(
      TYPES.AUTH_LOGOUT_SUCCESS,
      TYPES.CLOSE_WELCOME_MODAL
    )]: state => ({
      ...state,
      welcomeModalOpen: false
    })
  },
  initialState
);

export default persistReducer(persistConfig, reducer);

// Selectors
export const welcomeModalOpenSelector = state => state.Modal.welcomeModalOpen;
export const changePasswordModalSelector = state =>
  state.Modal.changePasswordModalOpen;
