import TYPES from '.';

export const closeWelcomeModal = () => ({
  type: TYPES.CLOSE_WELCOME_MODAL
});

export const closeChangePasswordModal = () => ({
  type: TYPES.CLOSE_CHANGE_PASSWORD_MODAL
});

export const openChangePasswordModal = () => ({
  type: TYPES.OPEN_CHANGE_PASSWORD_MODAL
});
