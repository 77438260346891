/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import { Reducer } from 'redux-actions';
import { StateType } from 'typesafe-actions';
import TYPES from '../actions';
import ApiReducer from '../services/api/state/reducer';
import AuthenticationReducer from './Authentication';
import BusinessReducer from './Business';
import CardReducer from './Card';
import EmailReducer from './Email';
import ErrorReducer from './Error';
import FormReducer from './Form';
import ModalReducer from './Modal';
import OnboardingReducer from './Onboarding';
import RegistrationReducer from './Registration';
import RouterReducer from './Router';
import StoreReducer from './Store';
import {
  EmailState,
  IAction,
  OnboardingState,
  StoreState,
  UserState
} from './types';
import UserReducer from './User';

const rootReducer = combineReducers({
  Api: ApiReducer,
  Authentication: AuthenticationReducer as Reducer<any, any>,
  Business: BusinessReducer as Reducer<any, any>,
  Card: CardReducer as Reducer<any, any>,
  Email: EmailReducer as Reducer<EmailState | undefined, IAction<any>>,
  Error: ErrorReducer as Reducer<any, any>,
  Form: FormReducer as Reducer<any, any>,
  Modal: ModalReducer as Reducer<any, any>,
  Onboarding: OnboardingReducer as Reducer<
    OnboardingState | undefined,
    IAction<any>
  >,
  Registration: RegistrationReducer as Reducer<any, any>,
  Router: RouterReducer as Reducer<any, any>,
  Store: StoreReducer as Reducer<StoreState | undefined, IAction<any>>,
  User: UserReducer as Reducer<UserState | undefined, IAction<any>>
});

const rootAndResetReducer = (
  state: RootState | undefined,
  action: IAction<any> & { payload: any }
) => {
  if (state && action.type === TYPES.AUTH_LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    delete state.Api;
  }

  return rootReducer(state, action);
};

export type RootState = StateType<typeof rootReducer>;

export default rootAndResetReducer;
