import filter from 'lodash.filter';
import { ApiState } from '../state/types';
import { CampaignSummary } from '../models/campaignSummary';
import { DeepPartial } from './types';

type CampaignSummaryIteratee =
  | ((summary: CampaignSummary) => boolean)
  | DeepPartial<CampaignSummary>;

export const filterSummaries = (
  state: ApiState,
  filterBy: CampaignSummaryIteratee
) => filter(state.campaignSummaries, filterBy as any);

export const isLoading = (state: ApiState) => state.loading.campaignSummaries;

export const selectSummary = (state: ApiState, campaignId: string) =>
  state.campaignSummaries[campaignId];
