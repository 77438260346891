import { AxiosResponse } from 'axios';
import { createStandardAction } from 'typesafe-actions';
import createAxiosAction from '../axios/createAxiosAction';
import { Localization } from '../models/localization';

export const findByStoreId = {
  request: createAxiosAction(
    '@@api/localization/findByStoreId',
    resolve => (storeId: number) =>
      resolve({
        client: 'customError',
        request: {
          method: 'get',
          url: `stores/${storeId}/localization`
        }
      })
  ),
  success: createStandardAction('@@api/localization/findByStoreId_SUCCESS')<
    AxiosResponse<Localization>
  >(),
  fail: createStandardAction('@@api/localization/findByStoreId_FAIL')<{}>()
};
