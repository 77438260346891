import { createBrowserHistory } from 'history';

function createHistory() {
  const history = createBrowserHistory();

  // Handle old hash URLs by parsing the hash and using the path to replace history. This also covers
  // the case where S3 is redirecting to index.html with a hash.
  // https://stackoverflow.com/a/34958026/651983
  if (history.location.pathname === '/' && history.location.hash) {
    const path = (/#(\/.*)$/.exec(history.location.hash) || [])[1];
    if (path) history.replace(path);
  }

  return history;
}

const history = createHistory();

export default history;
