import mixpanel from 'mixpanel-browser';
import dayjs from 'dayjs';
import { Business } from '../services/api/models/business';
import formatPhoneNumber from '../util/formatPhoneNumber';
import { User } from '../services/api/models/user';

const isImplicitlyOnboarded = (user: User): boolean => {
  const createdAt = dayjs(user.createdAt);
  return dayjs().diff(createdAt, 'day') >= 14;
};

const setup = (user: User, business: Business) => {
  const onboarded =
    Boolean(business.status.supportOnboarded) || isImplicitlyOnboarded(user);

  mixpanel.register({
    'Support Onboarded': onboarded
  });

  mixpanel.identify(`${business.id}`);
  mixpanel.people.set({
    'Business ID': business.id,
    $phone: formatPhoneNumber(business.phone).displayNumber,
    'Owner Phone': formatPhoneNumber(business.ownerPhone).displayNumber,
    'Owner Name': `${business.firstName} ${business.lastName}`,
    $name: business.name,
    $email: user.email,
    Website: business.url,
    'Store IDs': business.stores.map(store => store.id),
    'Store Count': business.stores.length
  });
  mixpanel.track('Login');
};

export default setup;
