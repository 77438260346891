/* eslint-disable no-underscore-dangle, no-case-declarations */
import { getType } from 'typesafe-actions';
import { Action } from 'redux-actions';
import * as userActions from './services/api/actions/users';

const hst = (arg: any[]) => {
  ((window as any)._hsq || []).push(arg);
};

export const hubspotTrackSPARoute = (route: string) => {
  hst(['setPath', route]);
  hst(['trackPageView']);
};

export const hubspotTrackUserDetail = (data: { [index: string]: string }) => {
  hst(['identify', data]);
};

export default () => (next: any) => (action: Action<any>) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      hubspotTrackSPARoute(action.payload.pathname);
      break;
    case getType(userActions.findById.success):
      const {
        payload: {
          data: { email }
        }
      } = action;
      hubspotTrackUserDetail({ email });
      break;
  }
  next(action);
};
