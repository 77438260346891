/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { handleActions, Reducer } from 'redux-actions';
import { getType } from 'typesafe-actions';
import TYPES from '../actions/index';
import * as businessActions from '../services/api/actions/businesses';
import * as userActions from '../services/api/actions/users';
// eslint-disable-next-line import/no-cycle
import { RootState } from './index';
// eslint-disable-next-line import/no-cycle
import { currentStoreIdSelector } from './Store';
import { IAction, UserState } from './types';
import { Store } from '../services/api/models/store';

const userState: UserState = {
  email: '',
  error: {},
  role: null
};

const reducer: Reducer<UserState, IAction<any>['payload']> = handleActions(
  {
    [getType(userActions.findById.success)]: (
      state: UserState,
      action: IAction<any>
    ) => ({
      ...action.payload.data,
      error: {}
    }),
    [getType(businessActions.findById.success)]: (
      state: UserState,
      action: IAction<any>
    ) =>
      action.payload.data.user
        ? { ...state, error: {}, business: action.payload.data }
        : state,
    [getType(businessActions.patch.success)]: (
      state: UserState,
      action: IAction<any>
    ) => {
      const { user, stores } = state.business;
      const business = { ...action.payload.data, user, stores };
      return { ...state, business, error: {} };
    },
    [TYPES.PATCH_STORE_SUCCESS]: (state: UserState, action: IAction<any>) => {
      const { stores } = state.business;
      const { data } = action.payload;
      const updatedStores = stores.map((store: any) => {
        const updatedStore = store.id === data.id ? data : {};
        return { ...store, ...updatedStore };
      });
      return {
        ...state,
        business: { ...state.business, stores: updatedStores }
      };
    },
    [TYPES.AUTH_LOGOUT_SUCCESS]: () => userState,
    [TYPES.UPDATE_BUSINESS_STATUS_SUCCESS]: (
      state: UserState,
      action: IAction<any>
    ) => ({
      ...state,
      business: {
        ...state.business,
        status: { ...action.payload.data }
      },
      error: {}
    }),
    [TYPES.UPDATE_BUSINESS_STATUS_FAIL]: (
      state: UserState,
      action: IAction<any>
    ) => {
      const {
        data: { error }
      } = action.error.response;

      return {
        ...state,
        error
      };
    },
    [TYPES.UPDATE_STORE_DEMOGRAPHICS_SUCCESS]: (
      state: UserState,
      action: IAction<any>
    ) => {
      const { stores } = state.business;
      const { data } = action.payload;
      const updatedStoresWithDemographics = stores.map((store: any) => {
        const currentStore = store;
        currentStore.demographics =
          store.id === data.storeId ? data : store.demographics;
        currentStore.territory = {
          ...store.territory,
          radius: data.territoryRadius
        };
        return currentStore;
      });
      return {
        ...state,
        business: { ...state.business, stores: updatedStoresWithDemographics }
      };
    }
  },
  userState
);

export default reducer;

// Selectors
export const currentUserSelector = (state: RootState) => state.User;

export const isUserAdminSelector = (state: RootState) =>
  (currentUserSelector(state) as UserState).role === 'admin';

export const currentBusinessSelector = (state: RootState) =>
  state.User!.business || {};

export const businessLoadedSelector = (state: RootState) =>
  Boolean(state.User!.business);

export const businessGroupIdSelector = (state: RootState) =>
  isUserAdminSelector(state)
    ? null
    : currentBusinessSelector(state).businessGroupId || null;

export const businessAcceptedTermsSelector = (state: RootState) =>
  Boolean('business' in state.User! && state.User!.business.status.acceptTerms);

export const currentStoreSelector = (state: RootState): Store => {
  const currentStoreId = currentStoreIdSelector(state);
  return 'business' in state.User!
    ? state.User!.business.stores.find(
        (store: any) => store.id === currentStoreId
      )
    : {};
};

export const allStoresSelector = (state: RootState): Store[] =>
  'business' in state.User! ? state.User!.business.stores : [];

export const storeCountSelector = (state: RootState) =>
  'business' in state.User! ? state.User!.business.stores.length : 0;

export const businessUserSelector = (state: RootState) =>
  isUserAdminSelector(state) && state.User!.business
    ? state.User!.business.user || {}
    : state.User!;

export const selfOnboardedSelector = (state: RootState) =>
  'business' in state.User! ? state.User!.business.status.selfOnboarded! : null;

export const errorSelector = (state: RootState) => state.User!.error;
