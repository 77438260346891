import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header } from 'semantic-ui-react';
import {
  Modal,
  HeaderGrid,
  FluidContent,
  Footer
} from '../../components/Modal';
import BusinessGrid from '../../components/BusinessGrid';
import { selectDefaultCard as selectDefaultCardAction } from '../../actions/Card';
import { fetchAndCheckBusiness } from '../../actions/Business';
import {
  selectedBusinessIdSelector,
  businessGroupSelector
} from '../../reducers/Business';
import { businessGroupIdSelector } from '../../reducers/User';
import { welcomeModalOpenSelector } from '../../reducers/Modal';

const mapStateToProps = state => ({
  open:
    businessGroupIdSelector(state) !== null &&
    selectedBusinessIdSelector(state) === null &&
    !welcomeModalOpenSelector(state),
  businessGroup: businessGroupSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAndCheckBusinessAction: fetchAndCheckBusiness,
      selectDefaultCard: selectDefaultCardAction
    },
    dispatch
  );

const BusinessSelectorModal = ({
  open,
  fetchAndCheckBusinessAction,
  businessGroup,
  selectDefaultCard
}) => (
  <Modal open={open}>
    <FluidContent>
      <HeaderGrid icon="browser">
        <Header as="h1">Choose Business</Header>
        <p>
          Select a business from the list below. Click on a selection to open
          into a new portal. You can always return to this screen by switching
          accounts under the menu.
        </p>
      </HeaderGrid>
    </FluidContent>
    <FluidContent>
      <BusinessGrid
        businesses={businessGroup.businesses}
        select={businessId => {
          selectDefaultCard('/');
          fetchAndCheckBusinessAction(businessId);
        }}
        padded
      />
    </FluidContent>
    <Footer />
  </Modal>
);

BusinessSelectorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  fetchAndCheckBusinessAction: PropTypes.func.isRequired,
  businessGroup: PropTypes.instanceOf(Object).isRequired,
  selectDefaultCard: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessSelectorModal);
