import { AxiosResponse } from 'axios';
import { createStandardAction } from 'typesafe-actions';
import createAxiosAction from '../axios/createAxiosAction';
import { CampaignSummary } from '../models/campaignSummary';

export const find = {
  request: createAxiosAction(
    '@@api/campaigns/find',
    resolve => (storeId: number) =>
      resolve({
        request: {
          method: 'get',
          url: `/stores/${storeId}/campaigns`
        }
      })
  ),
  success: createStandardAction('@@api/campaigns/find_SUCCESS')<
    AxiosResponse<CampaignSummary>
  >(),
  fail: createStandardAction('@@api/campaigns/find_FAIL')<{}>()
};
