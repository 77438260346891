/* eslint-disable @typescript-eslint/no-non-null-assertion */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import unionBy from 'lodash.unionby';
import { handleActions, Reducer } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getType } from 'typesafe-actions';
import TYPES from '../actions/index';
import * as businessActions from '../services/api/actions/businesses';
import * as userActions from '../services/api/actions/users';
import * as localizationActions from '../services/api/actions/localization';
// eslint-disable-next-line import/no-cycle
import { RootState } from './index';
import { IAction, StoreState } from './types';
import { StoreFile } from '../services/api/models/StoreFile';

dayjs.extend(advancedFormat);

const storeState: StoreState = {
  deleteFileId: null,
  downloadLeadsError: false,
  emailError: false,
  emails: [],
  facebookAds: [],
  fetchingEmails: false,
  files: [],
  isDownloadingLeads: false,
  isFetchingAds: false,
  isPatching: false,
  isUploadingImage: false,
  selected: null,
  uploadImageError: {},
  validationErrors: null,
  leadsCount: 0,
  isFetchingLocalization: false
};

const persistConfig = {
  storage,
  deleteFileId: null,
  key: 'root',
  whitelist: ['selected']
};

// Returns true if none of the incoming stores match the currently selected store
const isNewBusiness = (stores: any, selected: any) =>
  !stores.map((store: any) => store.id).includes(selected);

const reducer: Reducer<StoreState, IAction<any>['payload']> = handleActions(
  {
    [getType(businessActions.findById.success)]: (
      state: StoreState,
      action: IAction<any>
    ) => {
      const {
        data: { user, stores }
      } = action.payload;

      // Return initial state if business has no user
      if (!user) {
        return state;
      }

      return isNewBusiness(stores, state.selected)
        ? { ...state, selected: stores[0].id }
        : state;
    },
    [getType(userActions.findById.success)]: (
      state: StoreState,
      action: IAction<any>
    ) => {
      const {
        data: { business }
      } = action.payload;

      // Return initial state if user has no business
      if (!business) {
        return state;
      }

      return isNewBusiness(business.stores, state.selected)
        ? { ...state, selected: business.stores[0].id }
        : state;
    },
    [TYPES.SELECT_STORE]: (state: StoreState, action: IAction<any>) => ({
      ...state,
      selected: action.payload
    }),
    [TYPES.PATCH_STORE]: state => ({ ...state, isPatching: true }),
    [TYPES.PATCH_STORE_FAIL]: (state, action: IAction<any>) => {
      const {
        data: { error }
      } = action.error.response;
      return {
        ...state,
        isPatching: false,
        validationErrors: error.details.messages
      };
    },
    [TYPES.UPLOAD_STORE_IMAGE]: state => ({ ...state, isUploadingImage: true }),
    [TYPES.UPLOAD_STORE_IMAGE_SUCCESS]: state => ({
      ...state,
      isUploadingImage: false,
      uploadImageError: {}
    }),
    [TYPES.UPLOAD_STORE_IMAGE_FAIL]: (
      state: StoreState,
      action: IAction<any>
    ) => ({
      ...state,
      isUploadingImage: false,
      uploadImageError: action.payload
    }),
    [TYPES.STORE_FILE_FETCH_SUCCESS]: (
      state: StoreState,
      action: IAction<any>
    ) => ({
      ...state,
      files: action.payload.data
    }),
    [TYPES.SAVE_STORE_FILE_SUCCESS]: (
      state: StoreState,
      action: IAction<any>
    ) => ({
      ...state,
      files: unionBy([action.payload.data], state.files, 'type')
    }),
    [TYPES.CLEAN_UP_ERRORS]: (state: StoreState) => ({
      ...state,
      uploadImageError: {},
      validationErrors: null
    }),
    [TYPES.PATCH_STORE_SUCCESS]: (state: StoreState) => ({
      ...state,
      isPatching: false,
      validationErrors: null
    }),
    [TYPES.AUTH_LOGOUT_SUCCESS]: () => storeState,
    [TYPES.ADS_FETCH]: state => ({ ...state, isFetchingAds: true }),
    [TYPES.ADS_FETCH_SUCCESS]: (state: StoreState, action: IAction<any>) => ({
      ...state,
      facebookAds: action.payload.data,
      isFetchingAds: false
    }),
    [TYPES.DELETE_STORE_FILE]: (state: StoreState, action: IAction<any>) => ({
      ...state,
      deleteFileId: action.payload.id
    }),
    [TYPES.DELETE_STORE_FILE_SUCCESS]: (state: StoreState) => ({
      ...state,
      deleteFileId: null,
      files: state.files.filter((f: any) => f.id !== state.deleteFileId)
    }),
    [TYPES.FETCH_STORE_EMAILS]: (state: StoreState) => ({
      ...state,
      fetchingEmails: true
    }),
    [TYPES.FETCH_STORE_EMAILS_SUCCESS]: (
      state: StoreState,
      action: IAction<any>
    ) => ({
      ...state,
      emails: action.payload.data,
      fetchingEmails: false
    }),
    [TYPES.FETCH_STORE_EMAILS_FAIL]: (state: StoreState) => ({
      ...state,
      emailError: true,
      fetchingEmails: false
    }),
    [TYPES.CREATE_STORE_EMAIL_SUCCESS]: (
      state: StoreState,
      action: IAction<any>
    ) => ({
      ...state,
      emails: ([] as StoreState['emails']).concat(
        state.emails,
        action.payload.data
      )
    }),
    [TYPES.DOWNLOAD_STORE_LEADS]: (state: StoreState) => ({
      ...state,
      isDownloadingLeads: true
    }),
    [TYPES.DOWNLOAD_STORE_LEADS_FAIL]: (state: StoreState) => ({
      ...state,
      downloadLeadsError: true,
      isDownloadingLeads: false
    }),
    [TYPES.DOWNLOAD_STORE_LEADS_SUCCESS]: (state: StoreState) => ({
      ...state,
      isDownloadingLeads: false
    }),
    [TYPES.FETCH_STORE_LEADS_COUNT_SUCCESS]: (
      state: StoreState,
      action: IAction<any>
    ) => ({
      ...state,
      leadsCount: action.payload.data.count
    }),
    [getType(localizationActions.findByStoreId.request)]: (
      state: StoreState
    ) => ({
      ...state,
      isFetchingLocalization: true
    }),
    [getType(localizationActions.findByStoreId.success)]: (
      state: StoreState
    ) => ({
      ...state,
      isFetchingLocalization: false
    }),
    [getType(localizationActions.findByStoreId.fail)]: (state: StoreState) => ({
      ...state,
      isFetchingLocalization: false
    })
  },
  storeState
);

export default persistReducer(persistConfig, reducer as Reducer<
  any,
  IAction<any>['payload']
>);

// Selectors
export const currentStoreIdSelector = (state: RootState) =>
  state.Store!.selected || undefined;
export const facebookAdsSelector = (state: RootState) =>
  state.Store!.facebookAds;
export const loadingAds = (state: RootState) => state.Store!.isFetchingAds;
export const validationErrorselector = (state: RootState) =>
  state.Store!.validationErrors;
export const isPatchingStoreSelector = (state: RootState) =>
  state.Store!.isPatching;
export const isUploadingImageSelector = (state: RootState) =>
  state.Store!.isUploadingImage;
export const uploadImageErrorSelector = (state: RootState) =>
  state.Store!.uploadImageError;
export const isDownloadingLeadsSelector = (state: RootState) =>
  state.Store!.isDownloadingLeads;

export const downloadLeadsErrorSelector = (state: RootState) =>
  state.Store!.downloadLeadsError;

export const latestEmailDaySelector = (state: RootState) => {
  const [firstEmail] = state.Store!.emails;
  return firstEmail ? dayjs(firstEmail.createdAt) : undefined;
};

export const isFetchingEmailsSelector = (state: RootState) =>
  state.Store!.fetchingEmails;

export const emailErrorSelector = (state: RootState) => state.Store!.emailError;

/**
 * Selector to determine whether sending emails is currently disabled for the store.
 * The store's last sent email must have occured n days before the provided startDate.
 * If no startDate is provided, current date is used.
 *
 * @function
 * @param {Object} state Redux State
 * @param {String} startDate Optional start date
 * @returns {Boolean} Returns true if emails are disabled
 */
export const emailDisabledSelector = (state: RootState, startDate?: string) => {
  const minimumDays = Number(process.env.REACT_APP_EMAIL_INTERVAL) - 1;

  const latestEmail = latestEmailDaySelector(state);
  const emailError = emailErrorSelector(state);

  if (emailError) return true;

  return latestEmail
    ? dayjs(startDate).diff(latestEmail, 'day') < minimumDays
    : false;
};

/**
 * Selector to determine the date on which the next store email can be sent.
 *
 * @function
 * @param {Object} state Redux State
 * @returns {string} Formatted date. `Monday, December 3rd`.
 */
export const nextAvailableEmailSelector = (state: RootState) => {
  const latestDate = latestEmailDaySelector(state);
  const days = Number(process.env.REACT_APP_EMAIL_INTERVAL);
  return latestDate ? latestDate.add(days, 'day').format('dddd, MMM Do') : '';
};

export const storeLogoSelector = (state: RootState) => {
  const logo = state.Store!.files.find(f => f.type === 'logo');
  return logo ? logo.url : '';
};

export const leadsCountSelector = (state: RootState) => state.Store!.leadsCount;

export type StorePhotos = {
  primary: StoreFile;
  secondary: StoreFile;
  alternates: StoreFile[];
};

const placeholder = (type: string): StoreFile => ({
  id: 0,
  type,
  url: ''
});

export const storePhotosSelector = (state: RootState): StorePhotos => {
  const { files } = state.Store!;

  return {
    primary: files.find(f => f.type === 'primary') || placeholder('primary'),
    secondary:
      files.find(f => f.type === 'secondary') || placeholder('secondary'),
    alternates: files.filter(
      f => f.type === 'interior' || f.type === 'exterior'
    )
  };
};

export const storeLocalizationSelector = (state: RootState) => {
  const storeId = currentStoreIdSelector(state);
  return storeId ? state.Api.localization[storeId] : undefined;
};

export const storeColorSelector = (state: RootState) => {
  const storeId = currentStoreIdSelector(state);
  const localization = storeId ? state.Api.localization[storeId] : undefined;
  return localization ? localization.values.colorfillHexCode : undefined;
};

export const isFetchingLocalizationSelector = (state: RootState) =>
  state.Store!.isFetchingLocalization;
