/* eslint-disable @typescript-eslint/camelcase */
import { push } from 'react-router-redux';
import * as Sentry from '@sentry/browser';
import { generatePath } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { pay } from '../util/converge';
import TYPES, {
  createBusinessRegistration,
  fetchBusinessRegistrationPrice,
  fetchPaymentToken,
  formSave,
  formSaveError,
  isValidating,
  doneValidating
} from '.';
import { RouteNames } from '../util/routes';

export const FORM_TYPES = {
  SALES: 'sales',
  BUSINESS: 'business',
  STORE: 'store',
  PAYMENT_CREDIT: 'paymentCredit',
  PAYMENT_CHECK: 'paymentCheck',
  CONFIRMATION: 'confirmation'
};

export const saveSalesForm = formData => dispatch => {
  dispatch(formSave(FORM_TYPES.SALES, formData));
  dispatch(push(generatePath(RouteNames.signup)));
};

export const saveBusinessForm = formData => dispatch => {
  dispatch(formSave(FORM_TYPES.BUSINESS, formData));
  dispatch(push(generatePath(RouteNames['signup.2'])));
};

export const saveStoreForm = formData => async dispatch => {
  dispatch(formSave(FORM_TYPES.STORE, formData));
  await dispatch(
    fetchBusinessRegistrationPrice({ storeQuantity: formData.length })
  );
  dispatch(push(generatePath(RouteNames['signup.3'])));
};

export const setZapierBusinessData = businessData => dispatch => {
  dispatch({
    type: TYPES.SET_ZAPIER_BUSINESS_DATA,
    payload: businessData
  });
};

export const saveZapierBusiness = formData => async dispatch => {
  const id = uuidv4();
  const {
    businessName,
    corporateName,
    email,
    firstName,
    lastName,
    phone,
    promoCode
  } = formData;
  const businessData = {
    id,
    businessName,
    corporateName,
    email,
    firstName,
    lastName,
    phone,
    promoCode,
    createdAt: new Date().toString(),
    enrolled: false
  };

  dispatch(setZapierBusinessData(businessData));
  const res = await fetch(
    `${process.env.REACT_APP_ZAPIER_BUSINESS_DATA_HOOK_URL}`,
    {
      method: 'POST',
      body: JSON.stringify(businessData)
    }
  );
  if (!res.ok) {
    throw new Error('Error creating business in Zapier');
  }
};

export const updateZapierBusiness = async ({ id }, newData) => {
  const updateRes = await fetch(
    `${process.env.REACT_APP_ZAPIER_BUSINESS_DATA_HOOK_URL}`,
    {
      method: 'POST',
      body: JSON.stringify({ ...newData, id })
    }
  );
  if (!updateRes.ok) {
    throw new Error('Error creating business entry in Zapier');
  }
};

export const updateZapierStore = async storeData => {
  const storeRes = await fetch(
    `${process.env.REACT_APP_ZAPIER_STORE_DATA_HOOK_URL}`,
    {
      method: 'POST',
      body: JSON.stringify(storeData)
    }
  );
  if (!storeRes.ok) {
    throw new Error('Error creating store entry in Zapier');
  }
};

export const updateZapierBusinessEnrolled = async id => {
  const res = await fetch(
    `${process.env.REACT_APP_ZAPIER_BUSINESS_DATA_HOOK_URL}`,
    {
      method: 'POST',
      body: JSON.stringify({ id, enrolled: true })
    }
  );

  if (!res.ok) {
    throw new Error('Error updating business in Zapier');
  }
};

/**
 * The Redux Axios middleware returns a nested error object
 * @param {object} error Redux Axios' error object
 * @returns {Object} A flattened error object
 */

const flattenReduxAxiosError = err => {
  const {
    error: {
      response: {
        data: { error }
      }
    }
  } = err;
  return { status: error.statusCode, ...error.details };
};

const mapCheckoutJSError = err => ({ status: 422, ...err });

/**
 * Parse possible registration payment errors and
 * pass along useful error codes to user
 * @param {object} error error object
 * @returns {String} Error message
 */

export const parseErrors = error => {
  // Flatten API errors
  const message = error.type
    ? flattenReduxAxiosError(error)
    : mapCheckoutJSError(error);

  // Only display client facing Converge errors
  return message.status === 422 && message.errorName
    ? { userError: { message: message.errorName } }
    : { payment: { message: true } };
};

export const getRegistrationData = () => async dispatch => {
  await dispatch(fetchBusinessRegistrationPrice());
};

export const setInitPromoCode = string => dispatch => {
  dispatch({
    type: TYPES.SET_INIT_PROMO_CODE,
    payload: string
  });
};

export const applyPromoCode = ({
  promoCode,
  storeQuantity
}) => async dispatch => {
  await dispatch(fetchBusinessRegistrationPrice({ promoCode, storeQuantity }));
};

export const sendFormNoPayment = previousFormData => async dispatch => {
  const { business = {}, stores = [], referral, promoCode } = previousFormData;
  dispatch(isValidating());

  try {
    await dispatch(
      createBusinessRegistration({
        business: {
          name: business.businessName,
          corporateName: business.corporateName,
          firstName: business.firstName,
          lastName: business.lastName,
          email: business.email,
          ownerPhone: business.phone,
          sendTexts: business.sendTexts
        },
        stores: stores.map(store => ({
          street: store.address,
          state: store.state,
          city: store.city,
          zipCode: store.zipCode,
          neighborhood: store.neighborhood,
          url: store.website
        })),
        referral: referral
          ? {
              email: referral.email
            }
          : undefined,
        acceptTerms: business.acceptTerms,
        promoCode
      })
    );
    dispatch(doneValidating());
    dispatch(formSave('noPayment', previousFormData));
    dispatch(push(generatePath(RouteNames['signup.4'])));
  } catch (err) {
    dispatch(doneValidating());
    dispatch(formSaveError(parseErrors(err)));
    Sentry.captureException('No Auth business registration error');
  }
};

export const sendFormCredit = (
  previousFormData,
  formData
) => async dispatch => {
  const {
    business = {},
    stores = [],
    referral,
    price,
    promoCode
  } = previousFormData;
  dispatch(isValidating());
  try {
    const paymentTokenRes = await dispatch(fetchPaymentToken());
    const { token: paymentToken, paymentId } = paymentTokenRes.payload.data;

    const paymentData = {
      ssl_txn_auth_token: paymentToken,
      ssl_company: business.businessName,
      ssl_first_name: formData.cardFirstName,
      ssl_last_name: formData.cardLastName,
      ssl_card_number: formData.cardNumber,
      ssl_exp_date: formData.monthYear,
      ssl_cvv2cvc2_indicator: '1',
      ssl_cvv2cvc2: formData.cvv,
      ssl_avs_zip: formData.zipCode,
      ssl_amount: price,
      ssl_transaction_type: 'CCGETTOKEN',
      fanbank_payment_id: paymentId
    };

    const payRes = await pay(paymentData);

    await dispatch(
      createBusinessRegistration({
        creditCard: {
          token: payRes.ssl_token,
          company: business.businessName,
          firstName: formData.cardFirstName,
          lastName: formData.cardLastName,
          zip: formData.zipCode
        },
        business: {
          name: business.businessName,
          corporateName: business.corporateName,
          firstName: business.firstName,
          lastName: business.lastName,
          email: business.email,
          ownerPhone: business.phone,
          sendTexts: business.sendTexts
        },
        stores: stores.map(store => ({
          street: store.address,
          state: store.state,
          city: store.city,
          zipCode: store.zipCode,
          neighborhood: store.neighborhood,
          url: store.website
        })),
        referral: referral
          ? {
              email: referral.email
            }
          : undefined,
        paymentId,
        promoCode,
        acceptTerms: business.acceptTerms
      })
    );

    dispatch(doneValidating());
    dispatch(formSave(FORM_TYPES.PAYMENT_CREDIT, formData));
    dispatch(push(generatePath(RouteNames['signup.4'])));
  } catch (err) {
    dispatch(doneValidating());
    dispatch(formSaveError(parseErrors(err)));
    Sentry.captureException('Business registration credit card error');
  }
};

export const sendFormECheck = (
  previousFormData,
  formData
) => async dispatch => {
  const {
    business = {},
    stores = [],
    referral = {},
    promoCode
  } = previousFormData;
  dispatch(isValidating());
  try {
    await dispatch(
      createBusinessRegistration({
        eCheck: {
          abaNumber: formData.routingNumber,
          accountNumber: formData.bankAccountNumber,
          firstName: formData.businessName ? '' : formData.personalFirstName,
          lastName: formData.businessName ? '' : formData.personalLastName,
          company: formData.businessName ? formData.businessName : '',
          agree: true
        },
        business: {
          name: business.businessName,
          corporateName: business.corporateName,
          firstName: business.firstName,
          lastName: business.lastName,
          email: business.email,
          ownerPhone: business.phone,
          sendTexts: business.sendTexts
        },
        stores: stores.map(store => ({
          street: store.address,
          state: store.state,
          city: store.city,
          zipCode: store.zipCode,
          neighborhood: store.neighborhood,
          url: store.website
        })),
        referral: {
          email: referral.email
        },
        acceptTerms: business.acceptTerms,
        promoCode
      })
    );

    dispatch(doneValidating());
    dispatch(formSave(FORM_TYPES.PAYMENT_CHECK, formData));
    dispatch(push(generatePath(RouteNames['signup.4'])));
  } catch (err) {
    dispatch(doneValidating());
    dispatch(formSaveError(parseErrors(err)));
    Sentry.captureException('Business registration echeck error');
  }
};
