// Pull in the minified ES5 export
import 'autotrack/autotrack';

const setupGoogleAnalytics = trackingId => {
  const queue = (...args) => {
    (window.ga.q = window.ga.q || []).push(args);
  };

  // Setup Google Analytics or a queue for arguments until
  // the async script loads
  window.ga = window.ga || queue;
  window.ga.l = +new Date();

  window.ga('create', trackingId, 'auto');

  // Require all autotrack plugins
  window.ga('require', 'cleanUrlTracker');
  window.ga('require', 'eventTracker');
  window.ga('require', 'impressionTracker');
  window.ga('require', 'maxScrollTracker');
  window.ga('require', 'mediaQueryTracker');
  window.ga('require', 'outboundFormTracker');
  window.ga('require', 'outboundLinkTracker');
  window.ga('require', 'pageVisibilityTracker');
  window.ga('require', 'socialWidgetTracker');
  window.ga('require', 'urlChangeTracker');

  window.ga('send', 'pageview');
};

export default setupGoogleAnalytics;
