import { getType } from 'typesafe-actions';
import { findById as findUserById } from '../services/api/actions/users';
import TYPES from '../actions/index';

const DEFAULT_STATE = {
  asyncError: null
};

const Error = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TYPES.GLOBAL_ERROR:
      return Object.assign({}, state, {
        asyncError: {
          code: action.payload.statusCode,
          message: action.payload.message
        }
      });
    case TYPES.AXIOS_ERROR:
      return Object.assign({}, state, {
        asyncError: {
          code: 'Network Error',
          message: 'Unexpected Error: Server Connection Refused'
        }
      });
    case getType(findUserById.success):
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default Error;

// Selectors
export const asyncErrorSelector = state => state.Error.asyncError;
