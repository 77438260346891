import { handleActions } from 'redux-actions';
import { getType } from 'typesafe-actions';
import TYPES from '../actions/index';
import { findById, patch } from '../services/api/actions/businesses';

const DEFAULT_STATE = {
  isPatching: false,
  selected: null,
  validationErrors: null,
  businessGroup: {
    businesses: []
  },
  admin: {
    businesses: []
  }
};

const reducer = handleActions(
  {
    [getType(findById.success)]: (state, action) => {
      const {
        data: { user }
      } = action.payload;
      return user ? { ...state, selected: action.payload.data.id } : state;
    },
    [getType(patch.fail)]: (state, action) => {
      const {
        data: { error }
      } = action.error.response;
      return {
        ...state,
        isPatching: false,
        validationErrors: error.details.messages
      };
    },
    [TYPES.CLEAN_UP_ERRORS]: state => ({ ...state, validationErrors: null }),
    [getType(patch.request)]: state => ({ ...state, isPatching: true }),
    [getType(patch.success)]: state => ({
      ...state,
      isPatching: false,
      validationErrors: null
    }),
    [TYPES.CLEAR_BUSINESS]: state => ({
      ...state,
      selected: DEFAULT_STATE.selected
    }),
    [TYPES.BUSINESS_GROUP_FETCH_SUCCESS]: (state, action) => ({
      ...state,
      businessGroup: action.payload.data
    }),
    [TYPES.FETCH_ALL_BUSINESSES_SUCCESS]: (state, action) => ({
      ...state,
      admin: {
        businesses: action.payload.data.filter(business => business.name)
      }
    }),
    [TYPES.AUTH_LOGOUT_SUCCESS]: () => DEFAULT_STATE
  },
  DEFAULT_STATE
);

export default reducer;

// Selectors
export const selectedBusinessIdSelector = state => state.Business.selected;

export const businessGroupSelector = state => state.Business.businessGroup;

export const adminBusinessesSelector = state => state.Business.admin.businesses;

export const validationErrorSelector = state => state.Business.validationErrors;

export const isPatchingBusinessSelector = state => state.Business.isPatching;
