import TYPES from '../actions/index';

const initialState = {
  isFetchingData: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.IS_FETCHING_DATA:
      return { isFetchingData: true };
    case TYPES.DATA_FETCHED:
    case TYPES.AUTH_ERROR:
    case TYPES.AUTH_LOGIN_SUCCESS:
    case TYPES.AUTH_FORGOT_PASSWORD_SUCCESS:
    case TYPES.AUTH_RESET_PASSWORD_SUCCESS:
      return { isFetchingData: false };
    default:
      return state;
  }
};

export const isFetchingSelector = state => state.Form.isFetchingData;
