import { push } from 'react-router-redux';
import { generatePath } from 'react-router-dom';
import { createRequest, createAuthRequest, handleError } from '../util/api';
import TYPES, {
  authLoginSuccess,
  authResetPasswordSuccess,
  routeToLogin,
  routeToWelcome,
  isFetchingData,
  authError,
  dataFetched,
  globalError
} from './index';
import { RouteNames } from '../util/routes';

export const changePassword = (userId, newPassword) => async (
  dispatch,
  getState
) => {
  const { id, role } = getState().Authentication;
  const authRequest = createAuthRequest(id);
  try {
    dispatch(isFetchingData());
    const res = await authRequest.post(`/users/${userId}/change-password`, {
      newPassword
    });
    // Only authenticate with new token if the user is not an admin
    if (!role) dispatch(authLoginSuccess(res.data));
    dispatch(dataFetched());
    return res;
  } catch (err) {
    dispatch(authError());
    if (err && err.response) return err.response.data;
    return handleError(dispatch, err);
  }
};

export const loginUser = (email, password) => async dispatch => {
  try {
    dispatch(isFetchingData());
    const request = createRequest();
    const loginRes = await request.post(
      '/users/login',
      { email, password },
      {
        withCredentials: true
      }
    );
    const body = loginRes.data;
    if (loginRes.status === 200) {
      dispatch(authLoginSuccess(body));
    }

    return body;
  } catch (error) {
    dispatch(authError());
    return { error: error.response ? error.response.data.error : error };
  }
};

export const logoutUser = () => async (dispatch, getState) => {
  const { id: tokenId } = getState().Authentication;
  let body;

  try {
    const logoutRes = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/logout`,
      {
        method: 'POST',
        headers: {
          Authorization: tokenId
        },
        credentials: 'include'
      }
    );
    body = await logoutRes.json();
  } catch (error) {
    body = { error };
  }

  dispatch({
    type: TYPES.AUTH_LOGOUT_SUCCESS
  });
  dispatch(push(generatePath(RouteNames.portal)));

  return body;
};

export const forgotPassword = email => async dispatch => {
  try {
    dispatch(isFetchingData());
    const forgotRes = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/reset`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      }
    );

    const body = forgotRes.status !== 204 ? await forgotRes.json() : {};

    if (forgotRes.ok) {
      dispatch({
        type: TYPES.AUTH_FORGOT_PASSWORD_SUCCESS
      });
    }

    dispatch(dataFetched());

    return body;
  } catch (error) {
    dispatch(authError());
    return { error };
  }
};

export const resetPassword = (tokenId, newPassword) => async dispatch => {
  try {
    dispatch(isFetchingData());
    const authRequest = createAuthRequest(tokenId);
    const resetRes = await authRequest.post('/users/reset-password', {
      newPassword
    });
    const body = resetRes.data;

    if (resetRes.status === 200) {
      dispatch(authResetPasswordSuccess());
      dispatch(authLoginSuccess(body));
    }

    return body;
  } catch (error) {
    dispatch(authError());
    return { error: error.response ? error.response.data.error : error };
  }
};

export const exchangeToken = (token, destination) => async dispatch => {
  const request = createAuthRequest(token);
  try {
    const res = await request.post('/auth/exchange');
    dispatch(authLoginSuccess(res.data));
    dispatch(push(destination));
    return res.data;
  } catch (err) {
    if (
      err.response.status === 400 &&
      err.response.data.error.code === 'NO_PREVIOUS_LOGIN'
    )
      dispatch(routeToWelcome(token));
    else if (err.response.status === 401) dispatch(routeToLogin());
    else dispatch(globalError(err.response));
    return err;
  }
};

export const getUserSession = () => async dispatch => {
  try {
    dispatch(isFetchingData());
    const request = createRequest({ useV2: true });
    const sessionRes = await request.get('/merchants/reports/session', {
      withCredentials: true
    });
    const body = sessionRes.data;
    if (sessionRes.status === 200) {
      dispatch(
        authLoginSuccess({
          id: body.id,
          ttl: body.ttl,
          userId: body.merchantLoginId,
          created: body.created,
          role: body.role,
          lastLoginAt: null
        })
      );
    }

    return body;
  } catch (error) {
    dispatch(authError());
    return { error: error.response ? error.response.data.error : error };
  }
};
