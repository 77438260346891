import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const FluidContent = styled(Modal.Content)`
  padding: 0 !important;
  overflow: hidden;
  @media only screen and (max-width: 767px) {
    .ui.modal > &.content {
      padding: 0 !important;
    }
  }
`;
export default FluidContent;
