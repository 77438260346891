import { Modal as SemanticUIModal } from 'semantic-ui-react';
import styled from 'styled-components';

const Modal = styled(SemanticUIModal).attrs(() => ({
  dimmer: 'blurring',
  size: 'small'
}))`
  border-radius: 2rem !important;
  > :first-child {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
  }
  > :last-child {
    border-bottom-left-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }
  @media only screen and (min-width: 768px) {
    &.ui.mini {
      min-width: 340px;
    }
  }
`;

export default Modal;
