export default {
  white: '#ffffff',
  grey: '#e1e4ec',
  lightGray: '#f1f5f9',
  blueGradientStart: '#2a88ef',
  blueGradientEnd: '#4ba0f9',
  blue: '#334BC1',
  springGreen: '#00f1ba',
  cornflowerBlue: '#5caaff',
  blueTeal: '#4db5cb',
  darkGray: '#616b89',
  logoBlue: '#391ffd'
};
