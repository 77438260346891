import { AxiosResponse } from 'axios';
import { createStandardAction } from 'typesafe-actions';
import createAxiosAction from '../axios/createAxiosAction';
import { User } from '../models/user';

export const findById = {
  request: createAxiosAction('@@api/users/findById', resolve => (id: number) =>
    resolve({
      request: {
        method: 'get',
        url: `users/${id}`,
        params: { filter: { include: { business: 'stores' } } }
      }
    })
  ),
  success: createStandardAction('@@api/users/findById_SUCCESS')<
    AxiosResponse<User>
  >(),
  fail: createStandardAction('@@api/users/findById_FAIL')<{}>()
};
