import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header } from 'semantic-ui-react';
import AdminSearchInput from '../../components/AdminSearchInput';
import {
  Modal,
  HeaderGrid,
  FluidContent,
  ScrollingContent
} from '../../components/Modal';
import BusinessGrid from '../../components/BusinessGrid';
import { logoutUser } from '../../actions/Authentication';
import { fetchBusinessAndRoute as fetchBusinessAction } from '../../actions/Business';
import {
  selectedBusinessIdSelector,
  adminBusinessesSelector
} from '../../reducers/Business';
import { isUserAdminSelector, currentUserSelector } from '../../reducers/User';

const mapStateToProps = state => ({
  open:
    isUserAdminSelector(state) && selectedBusinessIdSelector(state) === null,
  user: currentUserSelector(state),
  businesses: adminBusinessesSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBusiness: fetchBusinessAction,
      logout: logoutUser
    },
    dispatch
  );

const RightLink = styled.a`
  z-index: 10;
  position: absolute;
  right: 2rem;
  top: 2rem;
`;

const AdminModal = styled(Modal)`
  ${ScrollingContent} {
    min-height: calc(70vh) !important;
  }
  margin-top: 1em;
  top: 0;
`;

class AdminSelectorModal extends Component {
  constructor(props) {
    super(props);
    this.state = { searchResults: null };
  }

  updateSearchResults = searchResults => this.setState({ searchResults });

  handleLogout = e => {
    const { logout } = this.props;
    e.preventDefault();
    logout();
  };

  render() {
    const { open, user, businesses, fetchBusiness } = this.props;

    const { searchResults } = this.state;

    return (
      <AdminModal open={open}>
        <FluidContent>
          <RightLink href="/" onClick={this.handleLogout}>
            Sign Out
          </RightLink>
          <HeaderGrid icon="configure">
            <Header as="h1">Admin Portal</Header>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
          </HeaderGrid>
        </FluidContent>
        <FluidContent>
          <AdminSearchInput
            businesses={businesses}
            updateSearchResults={this.updateSearchResults}
          />
        </FluidContent>
        <ScrollingContent>
          <BusinessGrid
            businesses={searchResults || businesses}
            select={fetchBusiness}
            padded
          />
        </ScrollingContent>
      </AdminModal>
    );
  }
}

AdminSelectorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  businesses: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchBusiness: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSelectorModal);
