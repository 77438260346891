import { createAction } from 'typesafe-actions';

interface IReduxAxiosMiddlewarePayload {
  client?: string;
  request: {
    url: string;
    method: 'get' | 'patch' | 'post';
    params?: {};
    data?: {};
  };
}

type CreateAction = typeof createAction;
type AxiosActionResolveHandler<T, AC> = (
  resolve: (
    payload: IReduxAxiosMiddlewarePayload
  ) => { type: T; payload: IReduxAxiosMiddlewarePayload }
) => AC;
interface IAxiosActionCreator extends CreateAction {
  <T extends string, AC>(
    actionType: T,
    actionResolveHandler: AxiosActionResolveHandler<T, AC>
  ): AC;
}

/**
 * Creates action creators typed to match redux-axios-middleware action payloads.
 */
const createAxiosAction: IAxiosActionCreator = createAction;

export default createAxiosAction;
