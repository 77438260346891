import { FlagsProvider } from 'flag';
import React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from './containers/ErrorBoundary';
import Router from './containers/Router';
import flags from './flags';
import history from './history';
import StoreProvider from './StoreProvider';
import theme from './styledTheme';

export const AppConnect = (component: React.ComponentType<any>) => {
  const App: React.FC = () => (
    <StoreProvider>
      <FlagsProvider flags={flags}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ConnectedRouter history={history}>
              <Route path="/" component={component} />
            </ConnectedRouter>
          </ErrorBoundary>
        </ThemeProvider>
      </FlagsProvider>
    </StoreProvider>
  );

  return App;
};

export default AppConnect(Router);
