/**
 * @module actions
 */

import { generatePath } from 'react-router-dom';
import { push } from 'react-router-redux';
import { createAction, Action } from 'redux-actions';
import { createAsyncAction } from 'typesafe-actions';
import { RouteNames } from '../util/routes';

const TYPES = {
  PUSH: '@@router/CALL_HISTORY_METHOD',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',
  DATA_FETCHED: 'DATA_FETCHED',
  AUTH_FORGOT_PASSWORD_SUCCESS: 'AUTH_FORGOT_PASSWORD_SUCCESS',
  AUTH_RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
  SELECT_CARD: 'SELECT_CARD',
  SELECT_STORE: 'SELECT_STORE',
  CLOSE_WELCOME_MODAL: 'CLOSE_WELCOME_MODAL',
  CLOSE_CHANGE_PASSWORD_MODAL: 'CLOSE_CHANGE_PASSWORD_MODAL',
  OPEN_CHANGE_PASSWORD_MODAL: 'OPEN_CHANGE_PASSWORD_MODAL',
  AXIOS_ERROR: 'AXIOS_ERROR',
  GLOBAL_ERROR: 'GLOBAL_ERROR',
  IS_FETCHING_DATA: 'IS_FETCHING_DATA',

  CLEAN_UP_ERRORS: 'CLEAN_UP_ERRORS',

  CLEAR_BUSINESS: 'CLEAR_BUSINESS',

  SAVE_FORM: 'SAVE_FORM',
  SAVE_FORM_ERROR: 'SAVE_FORM_ERROR',
  IS_VALIDATING: 'IS_VALIDATING',
  DONE_VALIDATING: 'DONE_VALIDATING',

  BUSINESS_GROUP_FETCH: 'BUSINESS_GROUP_FETCH',
  BUSINESS_GROUP_FETCH_SUCCESS: 'BUSINESS_GROUP_FETCH_SUCCESS',
  BUSINESS_GROUP_FETCH_FAIL: 'BUSINESS_GROUP_FETCH_FAIL',

  SET_INIT_PROMO_CODE: 'SET_INIT_PROMO_CODE',

  BUSINESS_REGISTRATION_CREATE: 'BUSINESS_REGISTRATION_CREATE',
  BUSINESS_REGISTRATION_CREATE_SUCCESS: 'BUSINESS_REGISTRATION_CREATE_SUCCESS',
  BUSINESS_REGISTRATION_CREATE_FAIL: 'BUSINESS_REGISTRATION_CREATE_FAIL',
  FETCH_BUSINESS_REGISTRATION_PRICE: 'FETCH_BUSINESS_REGISTRATION_PRICE',
  FETCH_BUSINESS_REGISTRATION_PRICE_SUCCESS:
    'FETCH_BUSINESS_REGISTRATION_PRICE_SUCCESS',
  FETCH_BUSINESS_REGISTRATION_PRICE_FAIL:
    'FETCH_BUSINESS_REGISTRATION_PRICE_FAIL',

  FETCH_ALL_BUSINESSES: 'FETCH_ALL_BUSINESSES',
  FETCH_ALL_BUSINESSES_SUCCESS: 'FETCH_ALL_BUSINESSES_SUCCESS',
  FETCH_ALL_BUSINESSES_FAIL: 'FETCH_ALL_BUSINESSES_FAIL',

  PATCH_STORE: 'PATCH_STORE',
  PATCH_STORE_SUCCESS: 'PATCH_STORE_SUCCESS',
  PATCH_STORE_FAIL: 'PATCH_STORE_FAIL',

  UPDATE_BUSINESS_STATUS: 'UPDATE_BUSINESS_STATUS',
  UPDATE_BUSINESS_STATUS_SUCCESS: 'UPDATE_BUSINESS_STATUS_SUCCESS',
  UPDATE_BUSINESS_STATUS_FAIL: 'UPDATE_BUSINESS_STATUS_FAIL',
  UPDATE_SELF_ONBOARDING: 'UPDATE_SELF_ONBOARDING',
  UPDATE_SELF_ONBOARDING_SUCCESS: 'UPDATE_SELF_ONBOARDING_SUCCESS',
  UPDATE_ACCEPT_TERMS: 'UPDATE_ACCEPT_TERMS',
  UPDATE_ACCEPT_TERMS_SUCCESS: 'UPDATE_ACCEPT_TERMS_SUCCESS',

  ADS_FETCH: 'ADS_FETCH',
  ADS_FETCH_SUCCESS: 'ADS_FETCH_SUCCESS',
  ADS_FETCH_FAIL: 'ADS_FETCH_FAIL',

  STORE_FILE_FETCH: 'STORE_FILE_FETCH',
  STORE_FILE_FETCH_SUCCESS: 'STORE_FILE_FETCH_SUCCESS',
  STORE_FILE_FETCH_FAIL: 'STORE_FILE_FETCH_FAIL',

  // Email Actions
  FETCH_ALL_THEMES: 'FETCH_ALL_THEMES',
  FETCH_ALL_THEMES_SUCCESS: 'FETCH_ALL_THEMES_SUCCESS',
  FETCH_ALL_THEMES_FAIL: 'FETCH_ALL_THEMES_FAIL',

  FETCH_STORE_BRANDS: 'FETCH_STORE_BRANDS',
  FETCH_STORE_BRANDS_SUCCESS: 'FETCH_STORE_BRANDS_SUCCESS',

  FETCH_THEME: 'FETCH_THEME',
  FETCH_THEME_SUCCESS: 'FETCH_THEME_SUCCESS',
  FETCH_THEME_FAIL: 'FETCH_THEME_FAIL',

  PAYMENT_TOKEN_FETCH: 'PAYMENT_TOKEN_FETCH',
  PAYMENT_TOKEN_FETCH_SUCCESS: 'PAYMENT_TOKEN_FETCH_SUCCESS',
  PAYMENT_TOKEN_FETCH_FAIL: 'PAYMENT_TOKEN_FETCH_FAIL',

  SELECT_TEMPLATE: 'SELECT_TEMPLATE',
  CLEAR_THEME: 'CLEAR_THEME',

  // Store image actions
  UPLOAD_STORE_IMAGE: 'UPLOAD_STORE_IMAGE',
  UPLOAD_STORE_IMAGE_SUCCESS: 'UPLOAD_STORE_IMAGE_SUCCESS',
  UPLOAD_STORE_IMAGE_FAIL: 'UPLOAD_STORE_IMAGE_FAIL',

  // File upload actions
  FETCH_FILE_TOKEN: 'FETCH_FILE_TOKEN',
  FETCH_FILE_TOKEN_SUCCESS: 'FETCH_FILE_TOKEN_SUCCESS',
  FETCH_FILE_TOKEN_FAIL: 'FETCH_FILE_TOKEN_FAIL',

  UPLOAD_STORE_FILE_S3: 'UPLOAD_STORE_FILE_S3',
  UPLOAD_STORE_FILE_S3_SUCCESS: 'UPLOAD_STORE_FILE_S3_SUCCESS',
  UPLOAD_STORE_FILE_S3_FAIL: 'UPLOAD_STORE_FILE_S3_FAIL',

  SAVE_STORE_FILE: 'SAVE_STORE_FILE',
  SAVE_STORE_FILE_SUCCESS: 'SAVE_STORE_FILE_SUCCESS',
  SAVE_STORE_FILE_FAIL: 'SAVE_STORE_FILE_FAIL',
  DELETE_STORE_FILE: 'DELETE_STORE_FILE',
  DELETE_STORE_FILE_SUCCESS: 'DELETE_STORE_FILE_SUCCESS',
  DELETE_STORE_FILE_FAIL: 'DELETE_STORE_FILE_FAIL',

  FETCH_STORE_EMAILS: 'FETCH_STORE_EMAILS',
  FETCH_STORE_EMAILS_SUCCESS: 'FETCH_STORE_EMAILS_SUCCESS',
  FETCH_STORE_EMAILS_FAIL: 'FETCH_STORE_EMAILS_FAIL',

  NAVIGATE_LIBRARY: 'NAVIGATE_LIBRARY',
  NAVIGATE_IMPORT: 'NAVIGATE_IMPORT',

  CREATE_STORE_EMAIL_CONFIRM_OPENED: 'CREATE_STORE_EMAIL_CONFIRM_OPENED',
  CREATE_STORE_EMAIL: 'CREATE_STORE_EMAIL',
  CREATE_STORE_EMAIL_SUCCESS: 'CREATE_STORE_EMAIL_SUCCESS',
  CREATE_STORE_EMAIL_FAIL: 'CREATE_STORE_EMAIL_FAIL',

  FETCH_CAMPAIGN: 'FETCH_CAMPAIGN',

  FETCH_STORE_LEADS: 'FETCH_STORE_LEADS',
  FETCH_STORE_LEADS_SUCCESS: 'FETCH_STORE_LEADS_SUCCESS',
  FETCH_STORE_LEADS_FAIL: 'FETCH_STORE_LEADS_FAIL',
  FETCH_STORE_LEADS_COUNT: 'FETCH_STORE_LEADS_COUNT',
  FETCH_STORE_LEADS_COUNT_SUCCESS: 'FETCH_STORE_LEADS_COUNT_SUCCESS',
  FETCH_STORE_LEADS_COUNT_FAIL: 'FETCH_STORE_LEADS_COUNT_FAIL',

  DOWNLOAD_STORE_LEADS: 'DOWNLOAD_STORE_LEADS',
  DOWNLOAD_STORE_LEADS_SUCCESS: 'DOWNLOAD_STORE_LEADS_SUCCESS',
  DOWNLOAD_STORE_LEADS_FAIL: 'DOWNLOAD_STORE_LEADS_FAIL',

  CREATE_STORE_TEST_EMAIL: 'CREATE_STORE_TEST_EMAIL',
  CREATE_STORE_TEST_EMAIL_SUCCESS: 'CREATE_STORE_TEST_EMAIL_SUCCESS',
  CREATE_STORE_TEST_EMAIL_FAIL: 'CREATE_STORE_TEST_EMAIL_FAIL',

  TEST_EMAIL_DISABLED: 'TEST_EMAIL_DISABLED',

  SELECT_BRAND: 'SELECT_BRAND',
  FETCH_BRANDS: 'FETCH_BRANDS',
  FETCH_BRANDS_SUCCESS: 'FETCH_BRANDS_SUCCESS',
  FETCH_BRANDS_FAIL: 'FETCH_BRANDS_FAIL',
  SKIP_BRANDS: 'SKIP_BRANDS',
  UPDATE_STORE_BRANDS: 'UPDATE_STORE_BRANDS',
  UPDATE_STORE_BRANDS_SUCCESS: 'UPDATE_STORE_BRANDS_SUCCESS',
  UPDATE_STORE_BRANDS_FAIL: 'UPDATE_STORE_BRANDS_FAIL',
  SAVE_STORE_BRANDS: 'SAVE_STORE_BRANDS',
  SAVE_STORE_BRANDS_SUCCESS: 'SAVE_STORE_BRANDS_SUCCESS',
  SAVE_STORE_BRANDS_FAIL: 'SAVE_STORE_BRANDS_FAIL',
  SAVE_TARGET_AREA: 'SAVE_TARGET_AREA',
  SAVE_TARGET_AREA_SUCCESS: 'SAVE_TARGET_AREA_SUCCESS',
  SAVE_TARGET_AREA_FAIL: 'SAVE_TARGET_AREA_FAIL',
  SAVE_DEMOGRAPHICS: 'SAVE_DEMOGRAPHICS',
  SAVE_DEMOGRAPHICS_SUCCESS: 'SAVE_DEMOGRAPHICS_SUCCESS',
  SAVE_DEMOGRAPHICS_FAIL: 'SAVE_DEMOGRAPHICS_FAIL',
  UPDATE_STORE_DEMOGRAPHICS: 'UPDATE_STORE_DEMOGRAPHICS',
  UPDATE_STORE_DEMOGRAPHICS_SUCCESS: 'UPDATE_STORE_DEMOGRAPHICS_SUCCESS',
  UPDATE_STORE_DEMOGRAPHICS_FAIL: 'UPDATE_STORE_DEMOGRAPHICS_FAIL',
  SKIP_TARGET_AREA: 'SKIP_TARGET_AREA',
  SKIP_DEMOGRAPHICS: 'SKIP_DEMOGRAPHICS',
  ONBOARDING_NEXT_STEP: 'ONBOARDING_NEXT_STEP',
  IS_SAVING_DATA: 'IS_SAVING_DATA',

  NAVIGATE_BACK: 'NAVIGATE_BACK',

  EDIT_CAMPAIGN: 'EDIT_CAMPAIGN',
  EDIT_CAMPAIGN_FORM_SUBMIT: 'EDIT_CAMPAIGN_FORM_SUBMIT',
  EDIT_CAMPAIGN_FORM_CONFIRM: 'EDIT_CAMPAIGN_FORM_CONFIRM',

  COPY_LANDING_PAGE_LINK: 'COPY_LANDING_LINK',
  VIEW_LANDING_PAGE: 'VIEW_LANDING_PAGE',

  SET_ZAPIER_BUSINESS_DATA: 'SET_ZAPIER_BUSINESS_DATA'
};

/**
 * Fetch all themes for a store
 *
 * @function
 * @param {Number} storeId store id
 */
export const fetchAllThemes = createAction(
  TYPES.FETCH_ALL_THEMES,
  (storeId: number) => ({
    client: 'customError',
    request: {
      method: 'get',
      url: `stores/${storeId}/themes`,
      params: { filter: { fields: { content: false, templates: false } } }
    }
  })
);

/**
 * Create a store email
 *
 * @function
 * @param {Object} data
 */
export const createStoreEmail = createAction(
  TYPES.CREATE_STORE_EMAIL,
  (data: any) => ({
    client: 'customError',
    request: {
      data,
      method: 'post',
      url: `stores/${data.storeId}/emails`
    }
  })
);

/**
 * Create a store test email
 *
 * @function
 * @param {Object} data
 */
export const createStoreTestEmail = createAction(
  TYPES.CREATE_STORE_TEST_EMAIL,
  (data: any) => ({
    client: 'customError',
    request: {
      data,
      method: 'post',
      url: `stores/${data.storeId}/test-emails`
    }
  })
);

/**
 * Fetch campaign by id.
 *
 * @function
 * @param {Number} storeId
 * @param {Number} themeId
 */
export const fetchTheme = createAction(
  TYPES.FETCH_THEME,
  (storeId: number, themeId: number) => ({
    request: {
      method: 'get',
      url: `stores/${storeId}/themes/${themeId}`
    }
  })
);

/**
 * Create an action to request the API to create a business registration.
 *
 * @function
 * @param {Object} request request body to send to API. The request should only include a creditCard
 *   or eCheck property, not both.
 * @param {Object} request.creditCard business' credit card information
 * @param {string} request.creditCard.token Converge credit card token
 * @param {string} request.creditCard.company Company name
 * @param {string} request.creditCard.firstName First name of cardholder
 * @param {string} request.creditCard.lastName Last name of cardholder
 * @param {string} request.creditCard.zip Zip code of cardholder
 * @param {Object} request.eCheck electronic check information. An eCheck should only include a
 *   firstName and lastName or company property, not both.
 * @param {string} request.eCheck.abaNumber bank routing number
 * @param {string} request.eCheck.accountNumber bank account number
 * @param {boolean} request.eCheck.agree flag indicating check holder has agreed to ECS terms
 * @param {string} request.eCheck.firstName check holder's first name
 * @param {string} request.eCheck.lastName check holders' last name
 * @param {string} request.eCheck.company check holder's company name
 * @param {string} request.paymentId unique payment ID used to identify payment through the
 *   billing system. This should be provided by the API when a session token is created.
 * @param {string} request.promoCode business promotion code to apply for discounted price
 */
export const createBusinessRegistration = createAction(
  TYPES.BUSINESS_REGISTRATION_CREATE,
  (request: any) => ({
    client: 'customError',
    request: {
      method: 'post',
      url: 'business-registrations',
      data: {
        creditCard: request.creditCard
          ? {
              token: request.creditCard.token,
              company: request.creditCard.company,
              firstName: request.creditCard.firstName,
              lastName: request.creditCard.lastName,
              zip: request.creditCard.zip
            }
          : undefined,
        eCheck: request.eCheck
          ? {
              abaNumber: request.eCheck.abaNumber,
              accountNumber: request.eCheck.accountNumber,
              agree: Boolean(request.eCheck.agree),
              firstName: request.eCheck.firstName,
              lastName: request.eCheck.lastName,
              company: request.eCheck.company
            }
          : undefined,
        business: request.business,
        stores: request.stores,
        referral: request.referral
          ? {
              email: request.referral.email
            }
          : undefined,
        paymentId: request.paymentId,
        promoCode: request.promoCode,
        acceptTerms: request.acceptTerms
      }
    }
  })
);

export const fetchBusinessRegistrationPrice = createAction(
  TYPES.FETCH_BUSINESS_REGISTRATION_PRICE,
  ({ promoCode = '', storeQuantity = 1 } = {}) => ({
    client: 'customError',
    request: {
      method: 'get',
      url: 'business-registrations/price',
      params: { promoCode, storeQuantity }
    }
  })
);

/**
 * Create an action to request the API to create a payment token to use with checkout.js.
 *
 * @function
 * @param {Object} options override options for reduxApiMiddleware
 * @param {boolean} options.handleError set to `false` to disable response error handling
 */
export const fetchPaymentToken = createAction(
  TYPES.PAYMENT_TOKEN_FETCH,
  () => ({
    client: 'customError',
    request: {
      method: 'post',
      url: 'business-registrations/payment-token'
    }
  })
);

export const setTestEmailDisabled = (bool: boolean) => ({
  type: TYPES.TEST_EMAIL_DISABLED,
  payload: bool
});

export const saveOnboardingBrands = createAsyncAction(
  TYPES.SAVE_STORE_BRANDS,
  TYPES.SAVE_STORE_BRANDS_SUCCESS,
  TYPES.SAVE_STORE_BRANDS_FAIL
)<void, void, void>();

export const saveOnboardingTargetArea = createAsyncAction(
  TYPES.SAVE_TARGET_AREA,
  TYPES.SAVE_TARGET_AREA_SUCCESS,
  TYPES.SAVE_TARGET_AREA_FAIL
)<void, void, void>();

export const saveOnboardingDemographics = createAsyncAction(
  TYPES.SAVE_DEMOGRAPHICS,
  TYPES.SAVE_DEMOGRAPHICS_SUCCESS,
  TYPES.SAVE_DEMOGRAPHICS_FAIL
)<void, void, void>();

/* Any reducer can implement this action for cleaning out any errors in state */
export const cleanupErrors = createAction(TYPES.CLEAN_UP_ERRORS);

export const selectStore = createAction(TYPES.SELECT_STORE);

export const clearBusiness = createAction(TYPES.CLEAR_BUSINESS);

export const isFetchingData = createAction(TYPES.IS_FETCHING_DATA);
export const globalError = createAction(TYPES.GLOBAL_ERROR);
export const dataFetched = createAction(TYPES.DATA_FETCHED);
export const axiosError = createAction(TYPES.AXIOS_ERROR);

export const authError = createAction(TYPES.AUTH_ERROR);
export const authLoginSuccess = createAction(TYPES.AUTH_LOGIN_SUCCESS);
export const authLogoutSuccess = createAction(TYPES.AUTH_LOGOUT_SUCCESS);
export const authResetPasswordSuccess = createAction(
  TYPES.AUTH_RESET_PASSWORD_SUCCESS
);

export const formSave = createAction(
  TYPES.SAVE_FORM,
  (formName: string, formData: any) => ({
    [formName]: formData
  })
);

type HelpInfo = {
  action: string;
  data?: { [key: string]: any };
};

export const navigateLibrary = createAction(TYPES.NAVIGATE_LIBRARY);
export const navigateImport = createAction(TYPES.NAVIGATE_IMPORT);

export const editCampaign = createAction(TYPES.EDIT_CAMPAIGN);
export const editCampaignFormSubmit = createAction(
  TYPES.EDIT_CAMPAIGN_FORM_SUBMIT
);
export const editCampaignFormConfirm = createAction(
  TYPES.EDIT_CAMPAIGN_FORM_CONFIRM
);

export const formSaveError = createAction(TYPES.SAVE_FORM_ERROR);
export const selectTemplate = createAction(TYPES.SELECT_TEMPLATE);
export const clearTheme = createAction(TYPES.CLEAR_THEME);

export const isValidating = createAction(TYPES.IS_VALIDATING);
export const doneValidating = createAction(TYPES.DONE_VALIDATING);

export const routeToRoot = () => push(generatePath(RouteNames.portal));
export const routeToWelcome = (token: string) =>
  push(generatePath(RouteNames['welcome.tokenId'], { tokenId: token }));
export const routeToLogin = () => push(generatePath(RouteNames.login));

export const routeToProfile = () =>
  push(generatePath(RouteNames['portal.profile']));
export const routeToFan = () => push(generatePath(RouteNames['portal.fans']));
export const routeToThemeList = () => push(generatePath(RouteNames.email));
export const routeToTemplateEditor = (themeId: number) =>
  push(generatePath(RouteNames['email.themeId'], { themeId }));

export const routeToBusinessEdit = () =>
  push(generatePath(RouteNames['edit.business']));
export const routeToStoresEdit = () =>
  push(generatePath(RouteNames['edit.stores']));
export const routeToPhotosEdit = () =>
  push(generatePath(RouteNames['edit.photos']));
export const routeToPhotosCropEdit = (
  storeData: { storeId: number; businessId: number },
  file: File,
  type: string
) =>
  push({
    file,
    storeData,
    type,
    pathname: generatePath(RouteNames['edit.photos.crop'])
  });
export const routeToCampaignEdit = (
  campaignId: number,
  appId: string,
  offerKey: string
) =>
  push(
    generatePath(RouteNames['campaign.campaignId.edit.appId.offerKey'], {
      campaignId,
      appId,
      offerKey
    })
  );

export const routeToCampaigns = () =>
  push(generatePath(RouteNames['portal.campaigns']));

export const routeToCampaign = ({ campaignId }: { campaignId: number }) =>
  push(
    generatePath(RouteNames['campaigns.campaignId'], {
      campaignId
    })
  );
export const routeToCampaignAssetGroup = ({
  campaignId,
  groupType
}: {
  campaignId: number;
  groupType: string;
}) =>
  push(
    generatePath(RouteNames['campaigns.campaignId.assets.groupType'], {
      campaignId,
      groupType
    })
  );

export const routeToCampaignEmail = (
  campaignId: string | number,
  groupType: string,
  templateName: string
) =>
  push(
    generatePath(
      RouteNames['campaigns.campaignId.emailTemplate.templateName'],
      {
        campaignId,
        groupType,
        templateName
      }
    )
  );

export const downloadingStoreLeads = createAction<{ storeId: number }>(
  TYPES.DOWNLOAD_STORE_LEADS
);
export const downloadStoreLeadsSuccess = createAction(
  TYPES.DOWNLOAD_STORE_LEADS_SUCCESS
);
export const downloadStoreLeadsFail = createAction(
  TYPES.DOWNLOAD_STORE_LEADS_FAIL
);

export const uploadingStoreImage = createAction(TYPES.UPLOAD_STORE_IMAGE);
export const uploadStoreImageSuccess = createAction(
  TYPES.UPLOAD_STORE_IMAGE_SUCCESS
);
export const uploadStoreImageFail = createAction(TYPES.UPLOAD_STORE_IMAGE_FAIL);

export const navigateBack = (ac: any) => (...args: any[]) => (
  dispatch: (action: Action<any>) => any
) => {
  dispatch(ac(...args));
  dispatch({ type: TYPES.NAVIGATE_BACK, payload: {} });
};

export const viewLandingPage = createAction(TYPES.VIEW_LANDING_PAGE);
export const copyLandingLink = createAction(TYPES.COPY_LANDING_PAGE_LINK);

export default TYPES;
