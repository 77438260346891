import { Action } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { createStandardAction } from 'typesafe-actions';
import { findById, Options } from '../services/api/actions/campaigns';
import { RootState } from '../reducers';
import { routeToCampaign } from '.';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action<any>>;

type CampaignProps = {
  campaignId: number;
};

type HelpProps = {
  campaignId?: number;
};

type KitItemProps = {
  name: string;
  group: string;
} & CampaignProps;

type KitGroupProps = {
  group: string;
  category: string;
} & CampaignProps;

export const fetchCampaignById = (
  id: number,
  options?: Options
): ThunkResult<void> => async dispatch => {
  await dispatch(findById.request(id, options));
};

export const selectCampaignKitItem = createStandardAction(
  'SELECT_CAMPAIGN_KIT_ITEM'
)<KitItemProps>();

export const selectCampaignKitGroup = createStandardAction(
  'SELECT_CAMPAIGN_KIT_GROUP'
)<KitGroupProps>();

export const select = createStandardAction('SELECT_CAMPAIGN')<CampaignProps>();

export const selectCampaign = (
  props: CampaignProps
): ThunkResult<void> => dispatch => {
  dispatch(select(props));
  dispatch(routeToCampaign(props));
};

export const selectCampaignHighlight = createStandardAction(
  'SELECT_CAMPAIGN_HIGHLIGHT'
)<CampaignProps>();

export const downloadCampaignKit = createStandardAction(
  'DOWNLOAD_CAMPAIGN_KIT'
)<CampaignProps>();

export const previewCampaign = createStandardAction('PREVIEW_CAMPAIGN')<
  CampaignProps
>();

export const requestCampaignHelp = createStandardAction(
  'REQUEST_CAMPAIGN_HELP'
)<HelpProps>();

export const selectCampaignEdit = createStandardAction('SELECT_CAMPAIGN_EDIT')<
  CampaignProps
>();
