import TYPES from '../actions/index';

const LOGGED_OUT_STATE = {
  id: null,
  ttl: null,
  userId: null,
  created: null,
  lastLoginAt: null
};

const reducer = (state = LOGGED_OUT_STATE, action) => {
  switch (action.type) {
    case TYPES.AUTH_LOGIN_SUCCESS:
      return action.payload;
    case TYPES.AUTH_LOGOUT_SUCCESS:
      return LOGGED_OUT_STATE;
    default:
      return state;
  }
};

export const userIdSelector = state => state.Authentication.userId;

export default reducer;
