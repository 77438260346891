const formatPhoneNumber = number => {
  if (!number) {
    return {
      displayNumber: '',
      dialableNumber: ''
    };
  }

  const digits = number.replace(/\D/g, '');
  const p = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
  return {
    displayNumber: p ? `(${p[1]}) ${p[2]} - ${p[3]}` : number,
    dialableNumber: p ? `1${digits}` : number
  };
};

export default formatPhoneNumber;
