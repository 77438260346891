/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { handleActions, Reducer } from 'redux-actions';
import TYPES from '../actions';
// eslint-disable-next-line import/no-cycle
import { RootState } from './index';
import { IAction, OnboardingState } from './types';

const onboardingState: OnboardingState = {
  brands: [],
  currentStoreBrandIds: [],
  isSavingData: false
};

const reducer: Reducer<
  OnboardingState,
  IAction<any>['payload']
> = handleActions(
  {
    [TYPES.FETCH_BRANDS_SUCCESS]: (
      state: OnboardingState,
      action: IAction<any>
    ) => ({
      ...state,
      brands: action.payload.data
    }),
    [TYPES.FETCH_STORE_BRANDS_SUCCESS]: (
      state: OnboardingState,
      action: IAction<any>
    ) => {
      const { data: storeBrands } = action.payload;

      if (storeBrands.length === 0) {
        return {
          ...state,
          currentStoreBrandIds: storeBrands
        };
      }

      const currentStoreBrandIds = storeBrands.map(
        (storeBrand: any) => storeBrand.brandId
      );

      return {
        ...state,
        currentStoreBrandIds
      };
    },
    [TYPES.SELECT_BRAND]: (state: OnboardingState, action: IAction<any>) => ({
      ...state,
      currentStoreBrandIds: action.payload
    })
  },
  onboardingState
);

export default reducer;

export const brandsSelector = (state: RootState) => state.Onboarding!.brands;

export const currentStoreBrandIdsSelector = (state: RootState) =>
  state.Onboarding!.currentStoreBrandIds;

export const isSavingDataSelector = (state: RootState) =>
  !!state.Onboarding!.isSavingData;

export const currentStoreBrandsSelector = (state: RootState) => {
  const storeBrands = state
    .Onboarding!.currentStoreBrandIds // get brands that match the currentStoreBrandId
    .map(
      (brandId: any) =>
        state.Onboarding!.brands.filter((brand: any) => brand.id === brandId)[0]
    );

  return storeBrands;
};
