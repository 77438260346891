import { getType } from 'typesafe-actions';
import mixpanel from 'mixpanel-browser';
import { MixpanelMiddleware } from './types';
import TYPES from '../actions/index';
import setup from './setup';
import * as emailTrackers from './trackers/email-trackers';
import * as navTrackers from './trackers/navigation-trackers';
import * as onboardingTrackers from './trackers/onboarding-trackers';
import * as campaignEditTrackers from './trackers/campaign-edit-trackers';
import * as campaignTrackers from './trackers/campaign-trackers';
import * as userActions from '../services/api/actions/users';
import * as campaignActions from '../actions/Campaign';

const isLoaded = Boolean(process.env.REACT_APP_MIXPANEL_APP_ID);

const middleware: MixpanelMiddleware = ({ getState }) => next => action => {
  // Ensure mixpanel is configured before processing middleware
  if (!isLoaded) return next(action);

  const prevState = getState();

  switch (action.type) {
    /* Setup mixpanel */
    case getType(userActions.findById.success): {
      const { business, ...user } = action.payload.data;
      if (business) {
        setup(user, business);
      } else mixpanel.disable();

      break;
    }

    /* Email Trackers */
    case TYPES.DOWNLOAD_STORE_LEADS:
      emailTrackers.trackDownloadedStoreLeads(prevState, action);
      break;
    case TYPES.FETCH_THEME_SUCCESS:
      emailTrackers.trackSelectedTheme(prevState, action);
      break;
    case TYPES.CREATE_STORE_EMAIL_CONFIRM_OPENED:
    case TYPES.CREATE_STORE_TEST_EMAIL_SUCCESS:
    case TYPES.CREATE_STORE_EMAIL_SUCCESS:
      emailTrackers.trackEmailFanbase(prevState, action);
      break;

    /* Onboarding Trackers */
    case TYPES.FETCH_STORE_BRANDS_SUCCESS:
      onboardingTrackers.initBrandsCache(prevState, action);
      break;
    case TYPES.SAVE_STORE_BRANDS_SUCCESS:
      onboardingTrackers.trackChangedBrands(prevState, action);
      break;

    case TYPES.SAVE_TARGET_AREA:
      onboardingTrackers.initTargetAreaCache(prevState, action);
      break;
    case TYPES.SAVE_TARGET_AREA_SUCCESS:
      onboardingTrackers.trackChangedTargetArea(prevState, action);
      break;

    case TYPES.SAVE_DEMOGRAPHICS:
      onboardingTrackers.initDemographicsCache(prevState, action);
      break;
    case TYPES.SAVE_DEMOGRAPHICS_SUCCESS:
      onboardingTrackers.trackChangedDemographics(prevState, action);
      break;

    case TYPES.SKIP_BRANDS:
      onboardingTrackers.trackSkipBrands(prevState, action);
      break;
    case TYPES.SKIP_TARGET_AREA:
      onboardingTrackers.trackSkipTargetArea(prevState, action);
      break;
    case TYPES.SKIP_DEMOGRAPHICS:
      onboardingTrackers.trackSkipDemographics(prevState, action);
      break;

    /* Navigation trackers */
    case TYPES.SELECT_STORE:
      navTrackers.trackSelectedStore(prevState, action);
      break;
    case TYPES.SELECT_CARD:
      navTrackers.trackCardSelect(prevState, action);
      break;
    case '@@router/LOCATION_CHANGE':
      navTrackers.trackNavigation(prevState, action);
      break;
    case TYPES.NAVIGATE_BACK:
      navTrackers.trackBackNavigation(prevState, action);
      break;

    /* Campaign Edit trackers */
    case TYPES.EDIT_CAMPAIGN_FORM_SUBMIT:
      campaignEditTrackers.trackFormSubmit(prevState, action);
      break;
    case TYPES.EDIT_CAMPAIGN_FORM_CONFIRM:
      campaignEditTrackers.trackModalSubmit(prevState, action);
      break;

    /* Campaign trackers */
    case getType(campaignActions.previewCampaign):
      campaignTrackers.trackPreviewedCampaign(prevState, action);
      break;
    case getType(campaignActions.selectCampaignHighlight):
      campaignTrackers.trackSelectedHighlight(prevState, action);
      break;
    case getType(campaignActions.select):
      campaignTrackers.trackSelectedCampaign(prevState, action);
      break;
    case getType(campaignActions.selectCampaignKitGroup):
      campaignTrackers.trackSelectedKitCampaignGroup(prevState, action);
      break;
    case getType(campaignActions.selectCampaignKitItem):
      campaignTrackers.trackSelectedKitCampaignItem(prevState, action);
      break;
    case getType(campaignActions.downloadCampaignKit):
      campaignTrackers.trackDownload(prevState, action);
      break;
    case getType(campaignActions.selectCampaignEdit):
      campaignTrackers.trackCampaignEdit(prevState, action);
      break;
    case getType(campaignActions.requestCampaignHelp):
      campaignTrackers.trackHelpRequested(prevState, action);
      break;
  }
  return next(action);
};

export default middleware;
