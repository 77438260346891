/* eslint class-methods-use-this: ["error", { "exceptMethods": ["componentDidCatch", "componentWillReceiveProps"] }] */
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ErrorPage from '../components/ErrorPage';
import { asyncErrorSelector } from '../reducers/Error';

const mapStateToProps = state => ({
  asyncError: asyncErrorSelector(state)
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    const { asyncError } = this.props;
    if (asyncError) {
      Sentry.captureException(asyncError.code, {
        info: asyncError.message
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // Capture and report any async errors
    const { asyncError: error } = nextProps;
    this.setState({ error });
    if (error) {
      Sentry.captureException(error.code, { info: error.message });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Capture and report internal rendering errors
    this.setState({ error });
    Sentry.captureException(error, { info: errorInfo });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) return <ErrorPage />;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  asyncError: PropTypes.instanceOf(Object)
};

ErrorBoundary.defaultProps = {
  asyncError: {
    code: null,
    message: null
  }
};

export default connect(mapStateToProps)(ErrorBoundary);
