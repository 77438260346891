import mixpanel from 'mixpanel-browser';
import { Tracker } from '../types';
import { currentStoreIdSelector } from '../../reducers/Store';
import { getPreviousPathname } from '../../util/routes';
import { localCache, isNewOrSame, isSame, checkCache } from '../cache';
import capitalize from '../../util/capitalize';

export const trackSelectedStore: Tracker = (_, { payload }) => {
  if (checkCache('selectedStoreId', payload, isSame)) return;
  mixpanel.track(`Clicked Store Selector`, {
    'Store ID': payload
  });
};

export const trackCardSelect: Tracker = (_, { payload }) => {
  const [cardName] = payload.selected.split('/');
  if (checkCache('selectedCard', cardName, isNewOrSame)) return;

  mixpanel.track('Clicked Main Navigation', {
    Page: capitalize(cardName)
  });
};

export const trackNavigation: Tracker = (state, { payload }) => {
  const storeId = currentStoreIdSelector(state);
  localCache.pathnames.push(payload.pathname);

  if (payload.pathname === '/email') {
    mixpanel.track(`Fans - Clicked Email Your Fanbase`, {
      'Store ID': storeId
    });
  }
};

export const trackBackNavigation: Tracker = () => {
  mixpanel.track('Navigated back', {
    Previous: getPreviousPathname(localCache.pathnames, 1),
    Current: getPreviousPathname(localCache.pathnames, 0)
  });
};
