import React from 'react';
import { Button as SemanticUIButton, ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';
import colors from '../colors';

type Type = 'base' | 'submit' | 'download' | 'outline';
type Size =
  | 'mini'
  | 'tiny'
  | 'small'
  | 'medium'
  | 'large'
  | 'big'
  | 'huge'
  | 'massive';
type Props = {
  type?: Type;
  size?: Size;
} & ButtonProps;

const Button: React.SFC<Props> = ({ type = 'base', size = 'big', ...rest }) => {
  const BaseButton = styled(SemanticUIButton).attrs(() => ({
    size
  }))`
    &.ui.button {
      font-family: 'Open Sans';
      font-weight: 100;
      border-radius: 2rem;
      color: #000000;
      transition: none;
    }
  `;

  const SubmitButton = styled(BaseButton)`
    &.ui.button {
      background-color: ${colors.blue} !important;
      color: #ffffff;
    }
  `;

  const DownloadButton = styled(BaseButton)`
    &.ui.button {
      background: #626b87;
      color: #ffffff;
    }
  `;

  const OutlineButton = styled(BaseButton).attrs(() => ({
    basic: true
  }))`
    &.ui.button {
      color: #4f5776 !important;
      border: 1px solid #626b87;
      box-shadow: none;
      :hover,
      :focus {
        box-shadow: none;
      }
    }
  `;

  const buttonTypeMap: { [key: string]: React.SFC<ButtonProps> } = {
    base: BaseButton,
    submit: SubmitButton,
    download: DownloadButton,
    outline: OutlineButton
  };

  return React.createElement(buttonTypeMap[type], rest);
};

Button.defaultProps = {
  type: 'base',
  size: 'big'
};

export default Button;
