import { RootState } from './index';
import {
  selectCampaign,
  selectAllCampaigns
} from '../services/api/selectors/campaign';
import { selectRawCampaign } from '../services/api/selectors/rawCampaign';

export const campaignSelector = ({ Api }: RootState, campaignId: string) =>
  selectCampaign(Api, campaignId);

export const campaignsSelector = ({ Api }: RootState) =>
  selectAllCampaigns(Api);

export const rawCampaignSelector = ({ Api }: RootState, campaignId: string) =>
  selectRawCampaign(Api, campaignId);

type Options = {
  campaignId: string;
  groupType: string;
};

export const assetGroupSelector = (state: RootState, options: Options) => {
  const campaign = rawCampaignSelector(state, options.campaignId);
  if (!campaign) return null;

  const group = campaign.kit.assetCategories
    .map(c => c.assetGroups)
    .flat()
    .find(g => g.type === options.groupType);

  return group;
};
