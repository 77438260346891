type Retry = (
  fn: () => Promise<any>,
  retriesLeft?: number,
  interval?: number
) => Promise<any>;

// https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
const retry: Retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export default retry;
