/* eslint-disable consistent-return */
import React, { ReactNode } from 'react';
import { Loader } from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';

type Props = {
  isLoading?: boolean;
  children?: ReactNode;
};

const StyledLoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const FadingLoadOverlay = styled.div<{ out?: boolean }>`
  visibility: ${props =>
    props.out === true || props.out === undefined ? 'visible' : 'hidden'};
  animation: ${props => {
      if (props.out === false) return fadeOut;
    }}
    0.5s linear;
  transition: visibility 0.5s linear;
  height: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: space-around;
  position: absolute;
  z-index: 1001;
  background-image: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.5)
  ) !important;
`;

const LoadingOverlay: React.FC<Props> = ({ isLoading, children }) => {
  if (!children) {
    return (
      <div>
        <Loader active size="large" />
      </div>
    );
  }
  return (
    <StyledLoadingWrapper>
      <FadingLoadOverlay out={isLoading}>
        <Loader active size="large" />
      </FadingLoadOverlay>
      <>{children}</>
    </StyledLoadingWrapper>
  );
};

export default LoadingOverlay;
