import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Header, Image, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { closeWelcomeModal } from '../../actions/Modal';
import {
  isUserAdminSelector,
  currentBusinessSelector
} from '../../reducers/User';
import { welcomeModalOpenSelector } from '../../reducers/Modal';
import welcomeImage from '../../images/welcome.svg';

const mapStateToProps = state => ({
  business: currentBusinessSelector(state),
  open: !isUserAdminSelector(state) && welcomeModalOpenSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      close: closeWelcomeModal
    },
    dispatch
  );

const WelcomeModal = ({ open, close, business, className }) => {
  if (!business) return '';

  return (
    <Modal dimmer="blurring" open={open} size="small" className={className}>
      <ModalContent>
        <Modal.Description>
          <Header as="h1" textAlign="center">
            Increase Customer Value Now
          </Header>
          <StyledImage src={welcomeImage} centered />
          <p>
            Your custom enrollment materials are ready! Go to the Quick Start
            Guide or talk to customer success to start activating customers
            today.
          </p>
          <Button onClick={close}>Get Started</Button>
        </Modal.Description>
      </ModalContent>
    </Modal>
  );
};

WelcomeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  business: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired
};

const StyledWelcomeModal = styled(WelcomeModal)`
  &.ui.modal {
    text-align: center;
    border-radius: 2rem;
    position: relative;
  }
  &.ui.modal > :first-child {
    border-radius: 2rem;
    padding: 4rem;
  }
  p {
    font-size: 1.2rem;
    width: 450px;
    margin: auto;
    line-height: 2rem;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .ui.button {
    width: 200px;
    margin: 1em 0;
  }
`;

const StyledImage = styled(Image)`
  margin: 1em 0;
`;

const ModalContent = styled(Modal.Content)`
  padding-bottom: 2em !important;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledWelcomeModal);
