import { reduce, uniqBy } from 'lodash';
import { RootState } from './index';
import { currentStoreIdSelector } from './Store';
import {
  filterSummaries,
  isLoading,
  selectSummary
} from '../services/api/selectors/campaignSummary';
import {
  CampaignSummary,
  CampaignSummaryRanked
} from '../services/api/models/campaignSummary';

const {
  REACT_APP_LAST_CAMPAIGN_APP_IDS = '',
  REACT_APP_FIRST_CAMPAIGN_APP_IDS = '',
  REACT_APP_CAMPAIGN_BUSINESS_OBJECTIVES = '',
  REACT_APP_PROHIBITED_CAMPAIGN_TYPES = ''
} = process.env;

const reactAppLastCampaignAppIds =
  REACT_APP_LAST_CAMPAIGN_APP_IDS || '1065faf5-0bf8-4a06-89d5-9f23766ad891';
const reactAppFirstCampaignAppIds =
  REACT_APP_FIRST_CAMPAIGN_APP_IDS || '8da4ac39-7d8e-4f01-9e50-f18e3068f6da';
const reactAppProhibitedCampaignTypes =
  REACT_APP_PROHIBITED_CAMPAIGN_TYPES || 'none,custom-email';
const reactAppCampaignBusinessObjectives =
  REACT_APP_CAMPAIGN_BUSINESS_OBJECTIVES ||
  'drive-first-program-purchase,recognize-regulars,drive-second-program-purchase,increase-transaction-size,increase-frequency,reengage-lapsed';

const arrayToMap = (arr: string | string[]): { [k: string]: number } =>
  reduce(
    Array.isArray(arr) ? arr : arr.split(','),
    (all, k, i) => ({ ...all, [k]: i + 1 }), // avoid 0 to always be truthy
    {}
  );

const prohibitedCampaignsMap = arrayToMap(reactAppProhibitedCampaignTypes);
const firstCampaignAppIds = arrayToMap(reactAppFirstCampaignAppIds);
const lastCampaignApIds = arrayToMap(reactAppLastCampaignAppIds);
const businessObjectivesMap = arrayToMap(reactAppCampaignBusinessObjectives);

const byNewest = (
  firstCampaign: CampaignSummary,
  secondCampaign: CampaignSummary
) =>
  new Date(secondCampaign.createdAt).valueOf() -
  new Date(firstCampaign.createdAt).valueOf();

const storeSummarySelector = (state: RootState): CampaignSummary[] => {
  const storeId = currentStoreIdSelector(state);

  if (!storeId) return [];

  const filtered = filterSummaries(state.Api, s => s.storeId === storeId);
  return (filtered as unknown) as CampaignSummary[];
};

const rankMapper = (
  campaign: CampaignSummary,
  index: number
): CampaignSummaryRanked => {
  const { appId } = campaign;

  let group: number;
  let priority: number;
  if (firstCampaignAppIds[appId]) {
    // First:
    // Campaign card in the carousel will be the specific app ID for
    // Enroll Customers.
    group = 1;
    priority = firstCampaignAppIds[appId];
  } else if (lastCampaignApIds[appId]) {
    // Last:
    // Campaign card in the carousel will be the specific app ID for
    // Build Your Fanbase
    group = 5;
    priority = lastCampaignApIds[appId];
  } else if (
    Array.isArray(campaign.businessObjectives) &&
    campaign.businessObjectives.length
  ) {
    // Second:
    // Campaigns w/ business objectives:
    // - Drive First Program Purchase
    // - Recognize Regulars
    // - Drive Second Program Purchase
    // - Increase Transaction Size
    // - Increase Frequency
    // - Re-engage Lapsed Customers
    group = 2;
    priority = 0;
    campaign.businessObjectives.forEach(objective => {
      priority += businessObjectivesMap[objective] || 0;
    });
  } else {
    // The rest of the campaigns as ordered before in chronological order.
    group = 3;
    priority = index;
  }

  const rank = Math.pow(10, group) + priority;
  return { ...campaign, rank };
};

const byRank = (a: CampaignSummaryRanked, b: CampaignSummaryRanked) =>
  a.rank - b.rank;

export const sortCampaigns = (campaigns: CampaignSummary[]) =>
  uniqBy(
    campaigns
      // 1. Filter out prohibited types and sort campaigns chronologically
      .filter(s => !prohibitedCampaignsMap[s.offer.type])
      // 2. Order in chronological order.
      .sort(byNewest as (a: any, b: any) => number)
      // 3. Apply a rank value.
      .map(rankMapper)
      // 4. Sort by rank.
      .sort(byRank as (a: any, b: any) => number),
    'appId'
  );

export const highlightsSelector = (state: RootState): CampaignSummary[] =>
  sortCampaigns(storeSummarySelector(state));

export const appSiblingsSelector = (
  { Api }: RootState,
  campaignId: string
): CampaignSummary[] => {
  const summary = selectSummary(Api, campaignId);
  if (!summary) return [];
  const siblings = filterSummaries(
    Api,
    s => s.appId === summary.appId && s.storeId === summary.storeId
  ).sort(byNewest as (a: any, b: any) => number);

  return (uniqBy(siblings, 'slug') as unknown) as CampaignSummary[];
};

export const campaignSummarySelector = (
  { Api }: RootState,
  campaignId: string
) => selectSummary(Api, campaignId);

export const loadingCampaignSummarySelector = ({ Api }: RootState) =>
  isLoading(Api);
