import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SetPasswordForm from '../Auth/SetPasswordForm';
import { Modal, FluidContent } from '../../components/Modal';
import { changePasswordModalSelector } from '../../reducers/Modal';
import { changePassword as changePasswordAction } from '../../actions/Authentication';
import { closeChangePasswordModal } from '../../actions/Modal';
import { isFetchingSelector } from '../../reducers/Form';
import { businessUserSelector } from '../../reducers/User';

const ChangePasswordContent = styled(FluidContent)`
  text-align: center !important;
`;

const ChangePasswordContainer = styled.div`
  width: 100%;
  padding: 5% 10%;
`;

const mapStateToProps = state => ({
  open: changePasswordModalSelector(state),
  isFetching: isFetchingSelector(state),
  businessUser: businessUserSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword: changePasswordAction,
      closeModal: closeChangePasswordModal
    },
    dispatch
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  changePassword: dispatchProps.changePassword.bind(
    null,
    stateProps.businessUser.id
  )
});

const ChangePasswordModal = ({
  open,
  closeModal,
  businessUser,
  isFetching,
  changePassword
}) => (
  <Modal open={open} onClose={closeModal}>
    <ChangePasswordContent>
      <ChangePasswordContainer>
        <h1>Change password for</h1>
        <p>{businessUser.email}</p>
        <SetPasswordForm
          labels
          isFetching={isFetching}
          onSubmit={changePassword}
          onSuccess={closeModal}
          submitText="Change Password"
        />
      </ChangePasswordContainer>
    </ChangePasswordContent>
  </Modal>
);

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  businessUser: PropTypes.instanceOf(Object).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ChangePasswordModal);
