import pathToRegexp from 'path-to-regexp';

export enum RouteNames {
  login = '/login',
  'welcome.tokenId' = '/welcome/:tokenId',
  portal = '/',
  'portal.profile' = '/profile',
  'portal.neighborhood' = '/neighborhood',
  'portal.fans' = '/fans',
  'signup.sales' = '/signup/sales',
  'portal.campaigns' = '/campaigns',
  'portal.campaigns.id' = '/campaigns/:id',
  signup = '/signup',
  'signup.2' = '/signup/2',
  'signup.3' = '/signup/3',
  'signup.4' = '/signup/4',
  edit = '/edit',
  'edit.business' = '/edit/business',
  'edit.stores' = '/edit/stores',
  'edit.photos' = '/edit/photos',
  'edit.photos.crop' = '/edit/photos/crop',
  email = '/email',
  'email.themeId' = '/email/:themeId',
  'onboarding.intro' = '/onboarding',
  'onboarding.brands' = '/onboarding/brands',
  'onboarding.territory' = '/onboarding/territory',
  'onboarding.demographics' = '/onboarding/demographics',
  'forgot-password' = '/forgot-password',
  'reset-password.tokenId' = '/reset-password/:tokenId',
  'campaign.campaignId.edit.appId.offerKey' = '/campaign/:campaignId/edit/:appId/:offerKey',
  'campaigns.campaignId' = '/campaigns/:campaignId',
  'campaigns.campaignId.assets.groupType' = '/campaigns/:campaignId/assets/:groupType',
  'campaigns.campaignId.emailTemplate.templateName' = '/campaigns/:campaignId/emailTemplate/:templateName'
}

// Adapted from: https://github.com/pillarjs/path-to-regexp/issues/153#issuecomment-407139685
export function getParams(
  route: pathToRegexp.Path,
  pathname: string
): { [key: string]: string } {
  const keys: pathToRegexp.Key[] = [];
  const r = pathToRegexp(route, keys).exec(pathname);
  return r
    ? keys.reduce((acc, key, i) => ({ [key.name]: r[i + 1], ...acc }), {})
    : {};
}

export function getPreviousPathname(listOfPathnames: string[], index = 0) {
  const i = listOfPathnames.length - (index + 1);
  return listOfPathnames[i];
}
