/**
 * Utilities for interacting with the Plink API.
 *
 * @module util/api
 */

import axios from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { authLogoutSuccess, axiosError, globalError } from '../actions/index';

const API_CONSTANTS = {
  URL: `${process.env.REACT_APP_API_URL}/api`,
  V2_URL: `${process.env.REACT_APP_API_URL}/v2`
};

export const apiClient = () => {
  const API_URL = `${process.env.REACT_APP_API_URL}/api`;
  return axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' }
  });
};

/**
 * Create a redux-axios-middleware with Axios interceptors for attaching credentials to requests,
 * and handling response errors.
 *
 * Error handling can be disabled per action:
 *
 * ```javascript
 * dispatch({
 *   type: 'PAYMENT_TOKEN_FETCH',
 *   payload: {
 *     request: {
 *       method: 'post',
 *       url: 'business-registrations/payment-token',
 *     },
 *     handleError: false,
 *   },
 * });
 * ```
 *
 * @function
 * @param {Axios} axiosClient an instance of Axios
 * @param {function} tokenIdSelector redux selector for selecting auth token from store
 * @param {handleError} handleError a function for handling response errors
 */
export const reduxApiMiddleware = (
  axiosClient,
  customErrorClient,
  tokenIdSelector,
  handleError
) =>
  multiClientMiddleware({
    default: {
      client: axiosClient,
      options: {
        interceptors: {
          request: [
            ({ getState }, req) => {
              const tokenId = tokenIdSelector(getState());
              req.headers.Authorization = tokenId;
              return req;
            }
          ],
          response: [
            {
              error: ({ dispatch }, error) =>
                Promise.reject(handleError(dispatch, error))
            }
          ]
        }
      }
    },
    customError: {
      client: customErrorClient,
      options: {
        returnRejectedPromiseOnError: true,
        interceptors: {
          request: [
            ({ getState }, req) => {
              const tokenId = tokenIdSelector(getState());
              req.headers.Authorization = tokenId;
              return req;
            }
          ]
        }
      }
    },
    customURL: {
      client: axios.create({}),
      options: { returnRejectedPromiseOnError: true }
    }
  });

export const createAuthRequest = token =>
  axios.create({
    baseURL: API_CONSTANTS.URL,
    timeout: API_CONSTANTS.TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });

export const createRequest = ({ useV2 } = { useV2: false }) =>
  axios.create({
    baseURL: useV2 ? API_CONSTANTS.V2_URL : API_CONSTANTS.URL,
    timeout: API_CONSTANTS.TIMEOUT,
    headers: { 'Content-Type': 'application/json' }
  });

/**
 * A function for handling Axios error responses.
 * @callback handleError
 * @param {function} dispatch Redux's dispatch
 * @param {Object} err Axios error response
 */

export const handleError = (dispatch, err) => {
  if (err.response) {
    // Pass through validation errors
    if (err.response.status === 422) return err;

    return err.response.status === 401
      ? dispatch(authLogoutSuccess())
      : dispatch(globalError(err.response.data));
  }

  if (err.request) {
    return dispatch(axiosError());
  }

  return err;
};
