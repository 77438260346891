const getConverge = () => {
  if (!window.ConvergeEmbeddedPayment) {
    throw new Error("Converge's Checkout.js cannot be found");
  }

  return window.ConvergeEmbeddedPayment;
};

export default getConverge;

export const pay = paymentData => {
  const converge = getConverge();
  const promise = new Promise((res, rej) => {
    converge.pay(paymentData, {
      onError: rej,
      onDeclined: rej,
      onApproval: res
    });
  });
  return promise;
};
