import { push } from 'react-router-redux';
import { generatePath } from 'react-router-dom';
import TYPES from './index';
import { RouteNames } from '../util/routes';

export const selectCard = (selected, skipRouting = false) => async dispatch => {
  dispatch({
    type: TYPES.SELECT_CARD,
    payload: { selected }
  });

  if (!skipRouting) {
    dispatch(push(generatePath(RouteNames[`portal.${selected}`])));
  }
};

export const selectDefaultCard = pathname => dispatch => {
  const cardFromPathname = pathname.split('/').find(x => x.length > 0);

  dispatch(
    selectCard(cardFromPathname || 'campaigns', Boolean(cardFromPathname))
  );
};
