import mixpanel from 'mixpanel-browser';
import { Tracker } from '../types';

export const trackFormSubmit: Tracker = (_, { payload }) => {
  const { appId, appOfferName, appOfferType, values, campaignId } = payload;
  mixpanel.track('Campaign Edit - Form Submit', {
    'App ID': appId,
    'Offer Name': appOfferName,
    'Offer Type': appOfferType,
    'Fields Changed': values,
    'Campaign ID': campaignId
  });
};

export const trackModalSubmit: Tracker = (_, { payload }) => {
  const { appId, appOfferName, appOfferType, values, campaignId } = payload;
  mixpanel.track('Campaign Edit - Modal Submit', {
    'App ID': appId,
    'Offer Name': appOfferName,
    'Offer Type': appOfferType,
    'Fields Changed': values,
    'Campaign ID': campaignId
  });
};
