import merge from 'lodash.merge';
import { normalize, schema } from 'normalizr';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { ApiAction, ApiState } from './types';

const BusinessSchema = new schema.Entity('businesses');
const StoreSchema = new schema.Entity('stores');
const UserSchema = new schema.Entity('users');
const LocalizationSchema = new schema.Entity(
  'localization',
  {},
  { idAttribute: 'storeId' }
);
const CampaignSchema = new schema.Entity('campaigns');
const RawCampaignSchema = new schema.Entity('rawCampaigns');

const AppSchema = new schema.Entity(
  'apps',
  {},
  {
    idAttribute: value =>
      value.offerKey ? `${value.id}_${value.offerKey}` : value.id
  }
);

const CampaignSummarySchema = new schema.Entity('campaignSummaries');

BusinessSchema.define({ stores: [StoreSchema], user: UserSchema });
UserSchema.define({ business: BusinessSchema });

const emptyState: ApiState = {
  businesses: {},
  stores: {},
  users: {},
  apps: {},
  localization: {},
  campaigns: {},
  rawCampaigns: {},
  campaignSummaries: {},
  loading: {
    campaignSummaries: false
  }
};

const normalizeAction = (action: ApiAction): Partial<ApiState> => {
  switch (action.type) {
    case getType(actions.businesses.findById.success):
      return normalize(action.payload.data, BusinessSchema).entities;
    case getType(actions.businesses.patch.success):
      return normalize(action.payload.data, BusinessSchema).entities;
    case getType(actions.users.findById.success):
      return normalize(action.payload.data, UserSchema).entities;
    case getType(actions.apps.findById.success):
    case getType(actions.apps.findByOfferKey.success):
      return normalize(action.payload.data, AppSchema).entities;
    case getType(actions.localization.findByStoreId.success):
      return normalize(action.payload.data, LocalizationSchema).entities;
    case getType(actions.campaigns.findById.success): {
      if (action.payload.data.interpolated)
        return normalize(action.payload.data, CampaignSchema).entities;
      return normalize(action.payload.data, RawCampaignSchema).entities;
    }
    case getType(actions.campaignSummaries.find.success):
      return normalize(action.payload.data, [CampaignSummarySchema]).entities;
    default:
      return emptyState;
  }
};

const getLoadingState = (
  action: ApiAction,
  state?: ApiState
): ApiState['loading'] => {
  const loadingState = state ? state.loading : emptyState.loading;
  switch (action.type) {
    case getType(actions.campaignSummaries.find.request): {
      return { campaignSummaries: true };
    }
    case getType(actions.campaignSummaries.find.fail):
    case getType(actions.campaignSummaries.find.success): {
      return { campaignSummaries: false };
    }
    default:
      return loadingState;
  }
};

const reducer: Reducer<ApiState, ApiAction> = (state = emptyState, action) => {
  const entities = normalizeAction(action);
  const loading = getLoadingState(action, state);
  return {
    ...state,
    loading,
    businesses: merge({}, state.businesses, entities.businesses),
    stores: merge({}, state.stores, entities.stores),
    users: merge({}, state.users, entities.users),
    apps: merge({}, state.apps, entities.apps),
    localization: merge({}, state.localization, entities.localization),
    campaigns: merge({}, state.campaigns, entities.campaigns),
    rawCampaigns: merge({}, state.rawCampaigns, entities.rawCampaigns),
    campaignSummaries: merge(
      {},
      state.campaignSummaries,
      entities.campaignSummaries
    )
  };
};

export default reducer;
