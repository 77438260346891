import isEqual from 'lodash.isequal';
import { LocalCache } from './types';

// eslint-disable-next-line import/no-mutable-exports
export let localCache: LocalCache = {
  pathnames: []
};

type Comparator = <R extends keyof LocalCache>(
  val: LocalCache[R],
  selected: LocalCache[R]
) => boolean;

export const isNew: Comparator = (_, selected) => !selected;

export const isSame: Comparator = (val, selected) => isEqual(val, selected);

export const isNewOrSame: Comparator = (val, selected) =>
  !selected || isEqual(val, selected);

export const isDifferent: Comparator = (val, selected) =>
  !isEqual(val, selected);

export const hasChanged: Comparator = (val, selected) =>
  Boolean(selected && !isEqual(val, selected));

export const checkCache = <R extends keyof LocalCache>(
  selector: R,
  value: LocalCache[R],
  comparator: Comparator
) => {
  const s = localCache[selector];
  localCache[selector] = value;
  return comparator(value, s);
};

export const readCache = <R extends keyof LocalCache>(
  selector: R,
  value: LocalCache[R],
  comparator: Comparator
) => {
  return comparator(value, localCache[selector]);
};

export const clearCache = () => {
  localCache = { pathnames: [] };
};
