/* eslint-disable no-undef */
import merge from 'lodash.merge';
import { Dispatch } from 'redux';
import TYPES from './actions/index';
import { IAction } from './reducers/types';
import { RouteNames, getPreviousPathname } from './util/routes';

type LocalState = {
  previousPathname: string[];
};

const defaultLocalState: LocalState = {
  previousPathname: []
};

const localState = merge({}, defaultLocalState);

export function sendLeadEvent(action: IAction<any>) {
  if (!action.payload.pathname) return;

  const prev = getPreviousPathname(localState.previousPathname, 1);
  const current = getPreviousPathname(localState.previousPathname, 0);

  if (prev === RouteNames.signup && current === RouteNames['signup.2']) {
    ga('send', {
      hitType: 'event',
      eventCategory: 'Signup',
      eventAction: 'next'
    });
  }
}

export default () => (next: Dispatch) => (action: IAction<any>) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case TYPES.BUSINESS_REGISTRATION_CREATE_SUCCESS:
      ga('send', {
        hitType: 'event',
        eventCategory: 'Signup',
        eventAction: 'paid'
      });
      break;
    case '@@router/LOCATION_CHANGE':
      localState.previousPathname.push(action.payload.pathname);
      sendLeadEvent(action);
      break;
  }
  next(action);
};
