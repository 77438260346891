import mixpanel from 'mixpanel-browser';
import { Tracker } from '../types';
import { currentStoreIdSelector } from '../../reducers/Store';
import TYPES from '../../actions/index';
import { selectedThemeSelector, themesSelector } from '../../reducers/Email';
import { getParams, RouteNames, getPreviousPathname } from '../../util/routes';
import { localCache } from '../cache';
import { campaignSelector, assetGroupSelector } from '../../reducers/Campaign';

export const trackDownloadedStoreLeads: Tracker = (_, action) => {
  mixpanel.track(`Fans - Clicked Export Fanbase`, {
    'Store ID': action.payload.storeId
  });
};

export const trackSelectedTheme: Tracker = (prevState, action) => {
  // if accessing email through campaigns do not track this event
  if (getPreviousPathname(localCache.pathnames, 0).includes('campaigns'))
    return;

  const selectedTheme = themesSelector(prevState).find(theme => {
    return theme.id === action.payload.data.id;
  });

  if (!selectedTheme) return;

  const storeId = currentStoreIdSelector(prevState);
  mixpanel.track(`Fans - Email Your Fanbase - Selected Theme`, {
    'App Name': selectedTheme.name,
    'Store ID': storeId
  });
};

export const trackEmailFanbase: Tracker = (prevState, action) => {
  const campaignRouteParams = getParams(
    RouteNames['campaigns.campaignId.emailTemplate.templateName'],
    getPreviousPathname(localCache.pathnames, 0)
  );
  const storeId = currentStoreIdSelector(prevState);
  const sentThroughCampaigns = Object.keys(campaignRouteParams).length > 0;
  const mixpanelEventTitle = sentThroughCampaigns ? 'Campaigns' : 'Fans';
  let propsToSend = {};

  if (sentThroughCampaigns) {
    const { campaignId } = campaignRouteParams;
    const campaign = campaignSelector(prevState, campaignId);
    const assetGroup = assetGroupSelector(prevState, {
      campaignId,
      groupType: 'email'
    });
    propsToSend = {
      'App Name': campaign.name,
      'Offer Name': campaign.offer.name,
      'Asset Group Name': assetGroup ? assetGroup.name : '',
      'Store ID': storeId
    };
  } else {
    const selectedTheme = selectedThemeSelector(prevState) || { name: '' };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { selectedTemplateName } = prevState.Email!;

    propsToSend = {
      'App Name': selectedTheme.name,
      Template: selectedTemplateName,
      'Store ID': storeId
    };
  }

  if (action.type === TYPES.CREATE_STORE_TEST_EMAIL_SUCCESS) {
    mixpanel.track(
      `${mixpanelEventTitle} - Email Your Fanbase - Sent Test Email`,
      propsToSend
    );
  }
  if (action.type === TYPES.CREATE_STORE_EMAIL_CONFIRM_OPENED) {
    mixpanel.track(
      `${mixpanelEventTitle} - Email Your Fanbase - Clicked Send`,
      propsToSend
    );
  }
  if (action.type === TYPES.CREATE_STORE_EMAIL_SUCCESS) {
    mixpanel.track(
      `${mixpanelEventTitle} - Email Your Fanbase - Confirmed Send`,
      propsToSend
    );
  }
};
