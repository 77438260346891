import { handleActions } from 'redux-actions';
import TYPES from '../actions/index';
import { FORM_TYPES } from '../actions/Registration';

const DEFAULT = {
  price: {
    monthlyPrice: process.env.REACT_APP_MONTHLY_PRICE,
    monthlyPricePerStore: process.env.REACT_APP_MONTHLY_PRICE
  },
  promoCode: {},
  isValidating: false,
  formData: {},
  error: {},
  zapierBusinessData: {}
};

const reducer = handleActions(
  {
    [TYPES.FETCH_BUSINESS_REGISTRATION_PRICE_SUCCESS]: (state, action) => ({
      ...state,
      price: {
        monthlyPrice: action.payload.data.monthlyPrice,
        monthlyPricePerStore: action.payload.data.monthlyPricePerStore
      },
      promoCode: action.payload.data.promoCode || DEFAULT.promoCode
    }),
    [TYPES.FETCH_BUSINESS_REGISTRATION_PRICE_FAIL]: state => ({
      ...state,
      promoCode: { error: true }
    }),
    [TYPES.IS_VALIDATING]: state => ({ ...state, isValidating: true }),
    [TYPES.DONE_VALIDATING]: state => ({ ...state, isValidating: false }),
    [TYPES.SAVE_FORM]: (state, action) => ({
      ...state,
      formData: { ...state.formData, ...action.payload },
      error: {}
    }),
    [TYPES.SAVE_FORM_ERROR]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [TYPES.SET_INIT_PROMO_CODE]: (state, action) => ({
      ...state,
      initPromoCode: action.payload
    }),
    [TYPES.SET_ZAPIER_BUSINESS_DATA]: (state, action) => ({
      ...state,
      zapierBusinessData: {
        ...state.zapierBusinessData,
        ...action.payload
      }
    })
  },
  DEFAULT
);

export default reducer;

export const isValidatingSelector = state => state.Registration.isValidating;

export const businessFormSelector = state =>
  state.Registration.formData[FORM_TYPES.BUSINESS];

export const storeFormSelector = state =>
  state.Registration.formData[FORM_TYPES.STORE];

export const paymentCreditFormSelector = state =>
  state.Registration.formData[FORM_TYPES.PAYMENT_CREDIT];

export const paymentCheckFormSelector = state =>
  state.Registration.formData[FORM_TYPES.PAYMENT_CHECK];

export const formErrorSelector = state => state.Registration.error;

export const salesFormSelector = state =>
  state.Registration.formData[FORM_TYPES.SALES];

export const registrationPriceSelector = state => state.Registration.price;

export const promoCodeSelector = state => state.Registration.promoCode;

export const initPromoCodeSelector = state => state.Registration.initPromoCode;

export const zapierBusinessDataSelector = state =>
  state.Registration.zapierBusinessData;
