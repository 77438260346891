import { Dimmer, Segment, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { exchangeToken as exchangeTokenAction } from '../actions/Authentication';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exchangeToken: exchangeTokenAction
    },
    dispatch
  );

class TokenRedirect extends Component {
  static propTypes = {
    exchangeToken: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    redirectPath: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { exchangeToken, token, redirectPath } = this.props;
    exchangeToken(token, redirectPath);
  }

  render() {
    return (
      <Segment style={{ height: '100%' }}>
        <Dimmer active inverted>
          <Loader size="massive">Redirecting</Loader>
        </Dimmer>
      </Segment>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(TokenRedirect);
