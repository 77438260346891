import { routerMiddleware } from 'react-router-redux';
import * as redux from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import mixpanelAnalytics from './mixpanel/middleware';
import history from './history';
import facebookTracker from './facebookTracker';
import hubspotTracker from './hubspotTracker';
import googleAnalytics from './googleAnalytics';
import reducer, { RootState } from './reducers';
import { apiClient, handleError, reduxApiMiddleware } from './util/api';
import sentryMiddleware from './sentry/middleware';

function createStore(initialState?: object) {
  const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
    sentryMiddleware,
    reduxApiMiddleware(
      apiClient(),
      apiClient(),

      (store: RootState) => store.Authentication.id,
      handleError
    ),
    mixpanelAnalytics,
    facebookTracker,
    googleAnalytics,
    hubspotTracker
  ];
  return redux.createStore(
    reducer,
    initialState,
    composeWithDevTools(redux.applyMiddleware(...middleware))
  );
}

const store = createStore();

export default store;
