import { AxiosResponse } from 'axios';
import { createStandardAction } from 'typesafe-actions';
import createAxiosAction from '../axios/createAxiosAction';
import { Campaign } from '../models/campaign';

export type Options = {
  interpolate: boolean;
};

const defaultOptions = {
  interpolate: true
};

export const findById = {
  request: createAxiosAction(
    '@@api/campaigns/findById',
    resolve => (id: number, { interpolate }: Options = defaultOptions) =>
      resolve({
        request: {
          method: 'get',
          url: `/campaigns/${id}`,
          params: { filter: { interpolate } }
        }
      })
  ),
  success: createStandardAction('@@api/campaigns/findById_SUCCESS')<
    AxiosResponse<Campaign>
  >(),
  fail: createStandardAction('@@api/campaigns/findById_FAIL')<{}>()
};
