import styled from 'styled-components';

const Square = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0.25rem 1rem 0 rgba(21, 86, 157, 0.06);
  border-radius: 0.75rem;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  overflow: hidden;
  margin: auto;
`;

export default Square;
