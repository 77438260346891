export class Content {
  public name: string;

  public description?: string;

  public default?: string;

  public displayName?: string;

  public hidden?: boolean;

  public disabled?: boolean;

  public length?: number;

  public stages?: string[];

  public type?: string;

  public constructor(name: string) {
    this.name = name;
  }
}

export function createContentFromApi(data: any): Content {
  const content = new Content(data.name);
  content.default = data.default;
  content.description = data.description;
  content.displayName = data.displayName;
  content.hidden = data.hidden;
  content.disabled = data.disabled;
  content.length = data.length;
  content.stages = data.stages;
  content.type = data.type;
  return content;
}
