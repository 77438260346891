import TYPES from '../actions/index';
// eslint-disable-next-line import/no-cycle
import { currentStoreSelector } from './User';

const DEFAULT_STATE = {
  selected: null,
  cards: ['profile', 'neighborhood', 'fans', 'campaigns']
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TYPES.SELECT_CARD:
      return { ...state, selected: action.payload.selected };
    case TYPES.AUTH_LOGOUT_SUCCESS:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

// Selectors
export const selectedCardSelector = state => state.Card.selected || '';
export const cardsSelector = state => {
  const { User } = state;

  const selectedStore = currentStoreSelector(state);

  if (!User.business || !selectedStore) {
    return [];
  }

  return state.Card.cards;
};
