import React, {
  lazy,
  LazyExoticComponent,
  Suspense,
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import { generatePath, Route, Switch } from 'react-router-dom';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { bindActionCreators } from 'redux';
import LoadingOverlay from '../components/LoadingOverlay';
import { RootState } from '../reducers';
import { pathSelector, searchParamSelector } from '../reducers/Router';
import { RouteNames } from '../util/routes';
import AdminSelectorModal from './Modals/AdminSelectorModal';
import BusinessSelectorModal from './Modals/BusinessSelectorModal';
import ChangePasswordModal from './Modals/ChangePasswordModal';
import WelcomeModal from './Modals/WelcomeModal';
import TokenRedirect from './TokenRedirect';
import retry from '../util/retry';
import { getUserSession as getUserSessionAction } from '../actions/Authentication';

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getUserSession: getUserSessionAction
    },
    dispatch
  );

const Portal = lazy(() => retry(() => import('./Portal')));
const Welcome: LazyExoticComponent<any> = lazy(() =>
  retry(() => import('./Auth/Welcome'))
);
const Login = lazy(() => retry(() => import('./Auth/Login')));
const Registration = lazy(() => retry(() => import('./Auth/registration')));
const Edit = lazy(() => retry(() => import('./Edit')));
const Email = lazy(() => retry(() => import('./Email')));
const Onboarding = lazy(() => retry(() => import('./Onboarding')));
const ForgotPassword = lazy(() => retry(() => import('./Auth/ForgotPassword')));
const ResetPassword: LazyExoticComponent<any> = lazy(() =>
  retry(() => import('./Auth/ResetPassword'))
);
const KitOfferEmailTemplate = lazy(() =>
  retry(() => import('./Campaigns/KitOfferEmailTemplate'))
);
const EditOffer = lazy(() => retry(() => import('./EditOffer')));
const CampaignAssets = lazy(() =>
  retry(() => import('./Campaigns/CampaignAssets'))
);

const mapStateToProps = (state: RootState) => ({
  params: searchParamSelector(state),
  path: pathSelector(state)
});

const locationHelper = locationHelperBuilder({});

const userIsAuthenticated = connectedRouterRedirect<any, RootState>({
  authenticatedSelector: state => state.Authentication.id !== null,
  redirectPath: '/login'
});

const userIsNotAuthenticated = connectedRouterRedirect<any, RootState>({
  allowRedirectBack: false,
  authenticatedSelector: state => state.Authentication.id === null,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/'
});

const NoAuthLogin = userIsNotAuthenticated(Login);
const NoAuthForgotPassword = userIsNotAuthenticated(ForgotPassword);
const NoAuthResetPassword = userIsNotAuthenticated(ResetPassword);
const NoAuthWelcome = userIsNotAuthenticated(Welcome);
const AuthEdit = userIsAuthenticated(Edit);
const AuthEmail = userIsAuthenticated(Email);
const NoAuthRegistration = userIsNotAuthenticated(Registration);
const AuthPortal = userIsAuthenticated(Portal);
const AuthOnboarding = userIsAuthenticated(Onboarding);
const AuthKitOfferEmailTemplate = userIsAuthenticated(KitOfferEmailTemplate);
const AuthEditOffer = userIsAuthenticated(EditOffer);
const AuthCampaignAssets = userIsAuthenticated(CampaignAssets);

type Props = {
  params: {
    token?: string;
  };
  path: string;
  getUserSession: any;
};

const Router: React.FC<Props> = ({ params, path, getUserSession }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getUserSession();
      setLoading(false);
    })();
  }, [getUserSession]);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          {params.token && (
            <TokenRedirect token={params.token} redirectPath={path} />
          )}
          <Route path={RouteNames.login} exact component={NoAuthLogin} />
          <Route
            path={RouteNames['forgot-password']}
            exact
            component={NoAuthForgotPassword}
          />
          <Route
            path={RouteNames['reset-password.tokenId']}
            exact
            component={NoAuthResetPassword}
          />
          <Route
            path={RouteNames['welcome.tokenId']}
            exact
            component={NoAuthWelcome}
          />
          <Route path={generatePath(RouteNames.edit)} component={AuthEdit} />
          <Route path={generatePath(RouteNames.email)} component={AuthEmail} />
          <Route
            path={generatePath(RouteNames.signup)}
            component={NoAuthRegistration}
          />
          <Route
            path={generatePath(RouteNames['onboarding.intro'])}
            component={AuthOnboarding}
          />
          <Route
            path={generatePath(
              RouteNames['campaigns.campaignId.assets.groupType'],
              {
                campaignId: ':campaignId',
                groupType: ':groupType'
              }
            )}
            component={AuthCampaignAssets}
          />
          <Route
            path={generatePath(
              RouteNames['campaigns.campaignId.emailTemplate.templateName'],
              {
                campaignId: ':campaignId',
                templateName: ':templateName'
              }
            )}
            component={AuthKitOfferEmailTemplate}
          />
          <Route
            path={RouteNames['campaign.campaignId.edit.appId.offerKey']}
            component={AuthEditOffer}
          />
          <Route path="/" component={AuthPortal} />
        </Switch>
      </Suspense>
      <AdminSelectorModal />
      <WelcomeModal />
      <BusinessSelectorModal />
      <ChangePasswordModal />
    </>
  );
};

Router.defaultProps = {
  params: {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router);
