import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import debounce from '../util/debounce';

const searchOptions = { keys: ['name'], shouldSort: true, threshold: 0.3 };

class AdminSearchInput extends Component {
  performSearch = debounce(value => {
    const { updateSearchResults } = this.props;
    updateSearchResults(value.length > 0 ? this.fuse.search(value) : null);
    this.setState({ isLoading: false });
  }, 300);

  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    // Initialize Fuse search on load if we have businesses
    this.fuse =
      props.businesses.length > 1
        ? new Fuse(props.businesses, searchOptions)
        : null;
  }

  componentWillReceiveProps(nextProps) {
    const { businesses } = this.props;
    // Update our Fuse search if there's an updated business array
    if (
      nextProps.businesses.length > 0 &&
      nextProps.businesses !== businesses
    ) {
      this.fuse = new Fuse(nextProps.businesses, searchOptions);
    }
  }

  // Clear search results after unmounting
  componentWillUnmount() {
    const { updateSearchResults } = this.props;
    updateSearchResults(null);
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true });
    return this.performSearch(value);
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Input
        fluid
        icon="search"
        placeholder="Search Merchant"
        size="large"
        onChange={this.handleSearchChange}
        loading={isLoading}
        style={{ margin: '0 34px 34px' }}
      />
    );
  }
}

AdminSearchInput.propTypes = {
  businesses: PropTypes.instanceOf(Array).isRequired,
  updateSearchResults: PropTypes.func.isRequired
};

export default AdminSearchInput;
