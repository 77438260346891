import mixpanel from 'mixpanel-browser';
import { Tracker, StoreLocation, StoreRadius } from '../types';
import { allStoresSelector, currentStoreSelector } from '../../reducers/User';
import { Store } from '../../services/api/models/store';
import { checkCache, isNew, isDifferent } from '../cache';
import { currentStoreBrandIdsSelector } from '../../reducers/Onboarding';

const parseStoreLocations = (stores: Store[]): StoreLocation[] =>
  stores.map(({ demographics }) => ({
    storeId: demographics.storeId,
    latitude: demographics.latitude,
    longitude: demographics.longitude
  }));

const parseStoreRadii = (stores: Store[]): StoreRadius[] =>
  stores.map(({ demographics }) => ({
    storeId: demographics.storeId,
    territoryRadius: demographics.territoryRadius
  }));

export const initDemographicsCache: Tracker = state => {
  const { demographics } = currentStoreSelector(state);

  checkCache('selectedGender', demographics.gender, isNew);
  checkCache(
    'selectedAgeRange',
    [demographics.minAge, demographics.maxAge],
    isNew
  );
};

export const trackChangedDemographics: Tracker = state => {
  const { demographics } = currentStoreSelector(state);
  const changedGender = checkCache(
    'selectedGender',
    demographics.gender,
    isDifferent
  );

  const changedAgeRange = checkCache(
    'selectedAgeRange',
    [demographics.minAge, demographics.maxAge],
    isDifferent
  );

  mixpanel.track('Onboarding - Clicked Next', {
    Page: 'Demographics',
    'Changed Gender': changedGender,
    'Changed Age Range': changedAgeRange
  });
};

export const initTargetAreaCache: Tracker = state => {
  const stores: Store[] = allStoresSelector(state);

  const storeLocations = parseStoreLocations(stores);
  const storeRadii = parseStoreRadii(stores);

  checkCache('storeLocations', storeLocations, isNew);
  checkCache('storeRadii', storeRadii, isNew);
};

export const trackChangedTargetArea: Tracker = state => {
  const stores: Store[] = allStoresSelector(state);

  const changedLocation = checkCache(
    'storeLocations',
    parseStoreLocations(stores),
    isDifferent
  );

  const changedRadius = checkCache(
    'storeRadii',
    parseStoreRadii(stores),
    isDifferent
  );

  mixpanel.track('Onboarding - Clicked Next', {
    Page: 'Target Area',
    'Changed Location': changedLocation,
    'Changed Mile Radius': changedRadius
  });
};

export const initBrandsCache: Tracker = (_, { payload }) => {
  const brandIds = payload.data.map(
    (brand: { brandId: number }) => brand.brandId
  );

  checkCache('brandIds', brandIds, isNew);
};

export const trackChangedBrands: Tracker = state => {
  const brandIds = currentStoreBrandIdsSelector(state);
  const changed = checkCache('brandIds', brandIds, isDifferent);
  mixpanel.track(`Onboarding - Clicked Next`, {
    Page: 'Brands',
    'Changed Brands': changed
  });
};

export const trackSkipBrands: Tracker = () =>
  mixpanel.track('Onboarding - Skipped', { Page: 'Brands' });

export const trackSkipTargetArea: Tracker = () =>
  mixpanel.track('Onboarding - Skipped', { Page: 'Target Area' });

export const trackSkipDemographics: Tracker = () =>
  mixpanel.track('Onboarding - Skipped', { Page: 'Demographics' });
